<template>
  <div class="mt-n5">
    <div
      v-for="(shareItem, key) of filteredSummaryData"
      :key="key"
    >
      <v-row v-if="showCommonList(shareItem) || showPreferredList(shareItem) || showOthersConvertible(shareItem) || showOthersEip(shareItem)">
        <v-col
          v-if="shareItem.title"
          cols="12"
          class="mx-2 trsText--text text-h4 font-weight-medium"
        >
          {{ shareItem.title }}
        </v-col>
        <v-col
          v-if="shareItem.subTitle"
          cols="12"
          class="mx-2 trsText--text text-body-1 py-0"
        >
          {{ shareItem.subTitle }}
        </v-col>
        <v-col
          cols="12"
          class="pa-0"
        >
          <!-- Common & Preferred Share Section -->
          <common-trs-data-table
            v-if="!shareItem.convertibleList && !shareItem.optionList"
            :headers="summaryViewHeaders"
            :items="shareItem.shareList"
            :custom-filter="customFilter"
            show-expand
            class="trs-table-container-card pa-5"
            hide-default-footer
            :expanded.sync="shareItem.expanded"
            disable-pagination
            item-key="index"
          >
            <template v-slot:item.name="{item}">
              <span class="trsText--text font-weight-medium text-body-1">
                {{ item.name }}
              </span>
            </template>
            <template v-slot:item.number_outstanding="{item}">
              <span class="trsText--text font-weight-medium text-body-1">
                {{ item.shareList ? $number(parseFloat(calculateSum(item.shareList, 'number_outstanding'))) : 'NA' }}
              </span>
            </template>
            <template v-slot:item.ratio_outstanding="{item}">
              <span class="trsText--text font-weight-medium text-body-1">
                {{ item.shareList ? `${(calculateSum(item.shareList, 'ratio_outstanding')* 100).toFixed(2)} %` : 'NA' }}
              </span>
            </template>
            <template v-slot:item.fully_diluted="{item}">
              <span
                v-if="!item.warrantList || item.warrantList.length === 0"
                class="trsText--text font-weight-medium text-body-1"
              >
                {{ $number(parseFloat(calculateSum(item.shareList, 'number_fully_diluted'))) }}
              </span>
              <span
                v-else
                class="trsText--text font-weight-medium text-body-1"
              >
                {{ item.shareList ? $number(parseFloat(calculateSum([...item.shareList, ...item.warrantList], 'number_fully_diluted'))) : $number(parseFloat(calculateSum(item.warrantList, 'number_fully_diluted'))) }}
              </span>
            </template>
            <template v-slot:item.fully_diluted_ratio="{item}">
              <span
                v-if="!item.warrantList || item.warrantList.length === 0"
                class="trsText--text font-weight-medium text-body-1"
              >
                {{ (calculateSum(item.shareList, 'ratio_fully_diluted')* 100).toFixed(2) }} %
              </span>
              <span
                v-else
                class="trsText--text font-weight-medium text-body-1"
              >
                {{ item.shareList ? (calculateSum([...item.shareList, ...item.warrantList], 'ratio_fully_diluted')* 100).toFixed(2) : (calculateSum(item.warrantList, 'ratio_fully_diluted')* 100).toFixed(2) }} %
              </span>
            </template>
            <template
              v-slot:expanded-item="{ headers, item }"
            >
              <td
                :colspan="headers.length"
                class="inner-table"
              >
                <!-- Share Terms Section -->
                <common-trs-data-table
                  v-if="(item.shareList && item.shareList.length > 0)"
                  :headers="shareTermHeaders"
                  :items="item.shareList"
                  :custom-filter="customFilter"
                  show-expand
                  hide-default-header
                  hide-default-footer
                  :expanded.sync="item.expanded"
                  disable-pagination
                >
                  <template v-slot:item="{ expand, isExpanded, item, headers }">
                    <tr>
                      <td
                        :colspan="headers.length"
                      >
                        <v-row class="trsText--text font-weight-medium text-body-1 text-right">
                          <v-col
                            cols="4"
                            class="text-left"
                          >
                            <div class="d-flex ml-1">
                              <div
                                v-if="item.name != 'Total'"
                                class="cursor-pointer pr-1"
                                @click="expand(!isExpanded)"
                              >
                                <v-icon v-if="!isExpanded">
                                  mdi-chevron-down
                                </v-icon>
                                <v-icon v-else>
                                  mdi-chevron-up
                                </v-icon>
                              </div>
                              <div
                                v-for="(shareTerm, key) in shareTermList"
                                :key="key"
                              >
                                <share-term-badge
                                  v-if="shareTerm.id === item.share_terms_id"
                                  :type="shareTerm.type"
                                  :show-single="true"
                                />
                              </div>
                              <div
                                class="ml-0"
                              >
                                <span>
                                  {{ item.share_terms_name }}
                                </span>
                                <div class="trsText--text font-weight-light text-body-2 text--lighten-1 mt-2">
                                  {{ item.shares.length }} {{ item.shares.length === 1 ?'Holder':'Holders' }}
                                </div>
                              </div>
                            </div>
                          </v-col>

                          <v-col
                            cols="2"
                            class="pr-0"
                          >
                            {{ $number(item.number_outstanding) }}
                          </v-col>
                          <v-col cols="2">
                            {{ (item.ratio_outstanding*100).toFixed(2) }} %
                          </v-col>
                          <v-col
                            cols="2"
                            class="pr-0"
                          >
                            {{ $number(item.number_fully_diluted) }}
                          </v-col>
                          <v-col
                            cols="2"
                          >
                            {{ (item.ratio_fully_diluted*100).toFixed(2) }} %
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </template>

                  <template v-slot:expanded-item="{item, headers}">
                    <tr
                      class="cursor-pointer"
                    >
                      <td
                        :colspan="headers.length"
                      >
                        <v-row
                          v-for="(obj,key) in item.shares"
                          :key="key"
                          class="trsText--text text-body-2 text-right"
                          @click="navigateToDetailsPage(item.share_terms_id, item.share_terms_type, obj.holder)"
                        >
                          <v-col
                            offset="1"
                            cols="3"
                            class="text-left"
                          >
                            <span>{{ obj.holder.name }}</span>
                          </v-col>
                          <v-col
                            cols="2"
                            class="pr-0"
                          >
                            {{ $number(obj.number_outstanding) }}
                          </v-col>
                          <v-col cols="2">
                            {{ (obj.ratio_outstanding*100).toFixed(2) }}%
                          </v-col>
                          <v-col
                            cols="2"
                            class="pr-0"
                          >
                            {{ $number(obj.number_fully_diluted) }}
                          </v-col>
                          <v-col
                            cols="2"
                          >
                            {{ (obj.ratio_fully_diluted*100).toFixed(2) }}%
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </template>
                </common-trs-data-table>
                <!-- Warrant Terms Section -->
                <common-trs-data-table
                  v-if="item.warrantList && item.warrantList.length > 0"
                  :headers="shareTermHeaders"
                  :items="item.warrantList"
                  :custom-filter="customFilter"
                  show-expand
                  hide-default-header
                  hide-default-footer
                  :expanded.sync="item.expand"
                  disable-pagination
                >
                  <template v-slot:item="{ expand, isExpanded, item, headers }">
                    <tr>
                      <td
                        :colspan="headers.length"
                      >
                        <v-row class="trsText--text font-weight-medium text-body-1 text-right">
                          <v-col
                            cols="4"
                            class="text-left"
                          >
                            <div class="d-flex ml-1">
                              <div
                                v-if="item.name != 'Total'"
                                class="cursor-pointer pr-1"
                                @click="expand(!isExpanded)"
                              >
                                <v-icon v-if="!isExpanded">
                                  mdi-chevron-down
                                </v-icon>
                                <v-icon v-else>
                                  mdi-chevron-up
                                </v-icon>
                              </div>
                              <div
                                v-for="(term, key) in warrantTermList"
                                :key="key"
                              >
                                <share-term-badge
                                  v-if="term.id === item.warrant_terms_id"
                                  :type="CONSTS.SHARE_TERM_TYPES.WARRANT"
                                  :show-single="true"
                                />
                              </div>
                              <div
                                class="ml-0"
                              >
                                <span>
                                  {{ item.warrant_terms_name }}
                                </span>
                                <div class="trsText--text font-weight-light text-body-2 text--lighten-1 mt-2 pl-1">
                                  {{ item.warrants.length }} {{ item.warrants.length === 1 ?'Holder':'Holders' }}
                                </div>
                              </div>
                            </div>
                          </v-col>

                          <v-col
                            cols="2"
                          >
                            NA
                          </v-col>
                          <v-col cols="2">
                            NA
                          </v-col>
                          <v-col
                            cols="2"
                            class="pr-0"
                          >
                            {{ $number(item.number_fully_diluted) }}
                          </v-col>
                          <v-col
                            cols="2"
                          >
                            {{ (item.ratio_fully_diluted*100).toFixed(2) }}%
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </template>

                  <template v-slot:expanded-item="{item, headers}">
                    <tr
                      class="cursor-pointer"
                    >
                      <td
                        :colspan="headers.length"
                      >
                        <v-row
                          v-for="(obj,key) in item.warrants"
                          :key="key"
                          class="trsText--text text-body-2 text-right"
                          @click="navigateToDetailsPage(item.warrant_terms_id, CONSTS.SHARE_TERM_TYPES.WARRANT, obj.holder)"
                        >
                          <v-col
                            offset="1"
                            cols="3"
                            class="text-left"
                          >
                            <span>{{ obj.holder.name }}</span>
                          </v-col>
                          <v-col
                            cols="2"
                          >
                            NA
                          </v-col>
                          <v-col cols="2">
                            NA
                          </v-col>
                          <v-col
                            cols="2"
                            class="pr-0"
                          >
                            {{ $number(obj.number_fully_diluted) }}
                          </v-col>
                          <v-col
                            cols="2"
                            class="pr-0"
                          >
                            {{ (obj.ratio_fully_diluted*100).toFixed(2) }}%
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </template>
                </common-trs-data-table>
              </td>
            </template>
            <!-- Total Row -->
            <template v-slot:body.append="{ headers, items }">
              <tr>
                <td
                  :colspan="headers.length"
                  class="px-2 footer-bg"
                >
                  <v-row
                    class="trsText--text font-weight-bold text-body-1 text-right no-gutters"
                  >
                    <v-col
                      cols="4"
                      class="text-left pl-12"
                    >
                      Total
                    </v-col>
                    <v-col
                      cols="2"
                    >
                      <span class="mr-m2">
                        {{ $number(parseFloat(calculateShareSum(items, 'number_outstanding'))) }}
                      </span>
                    </v-col>
                    <v-col
                      cols="2"
                      class="pr-2"
                    >
                      <span>
                        {{ (calculateShareSum(items, 'ratio_outstanding') * 100).toFixed(2) }} %
                      </span>
                    </v-col>
                    <v-col
                      cols="2"
                      class="pr-0"
                    >
                      <span class="mr-m2">
                        {{ $number(parseFloat(calculateShareSum(items, 'number_fully_diluted'))) }}
                      </span>
                    </v-col>
                    <v-col
                      cols="2"
                      class="pr-2"
                    >
                      <span>
                        {{ (calculateShareSum(items, 'ratio_fully_diluted') * 100 ).toFixed(2) }} %
                      </span>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </template>
          </common-trs-data-table>
          <!-- Convertible Section -->
          <common-trs-data-table
            v-if="shareItem.convertibleList && showOthersConvertible(shareItem)"
            :headers="convertibleShareHeader"
            :items="shareItem.convertibleList"
            :custom-filter="customFilter"
            show-expand
            class="trs-table-container-card pa-5"
            hide-default-footer
            :expanded.sync="convertiblesExpanded"
            disable-pagination
            item-key="index"
          >
            <!-- Headers -->
            <template v-slot:[`header.name`]="{ header }">
              <span class="trs--text text-body-1 font-weight-medium"> {{ header.text }}</span>
            </template>
            <template v-slot:[`header.maturity`]="{ header }">
              <span class="trs--text text-body-1 font-weight-medium"> {{ header.text }}</span>
            </template>
            <template v-slot:[`header.all_principal`]="{ header }">
              <span>
                <div class="trs--text text-body-1 font-weight-medium"> {{ header.text }}</div>
                <span class="trsText--text text-body-2 mt-1">
                  {{ header.text2 }}
                </span>
              </span>
            </template>
            <template v-slot:[`header.all_interest`]="{ header }">
              <span>
                <div class="trs--text text-body-1 font-weight-medium"> {{ header.text }}</div>
                <span class="trsText--text text-body-2 mt-1">
                  {{ header.text2 }}
                </span>
              </span>
            </template>
            <template v-slot:[`header.all_total`]="{ header }">
              <span>
                <div class="trs--text text-body-1 font-weight-medium"> {{ header.text }}</div>
                <span class="trsText--text text-body-2 mt-1">
                  {{ header.text2 }}
                </span>
              </span>
            </template>

            <!-- Body -->
            <template v-slot:item.name="{item}">
              <span class="trsText--text font-weight-medium text-body-1">
                {{ item.name }}
              </span>
            </template>
            <template v-slot:item.all_principal="{item}">
              <span class="trsText--text font-weight-medium text-body-1">
                {{ $currencyWithPrecision(parseFloat(calculateConvertibleSum(item.convertibleList, 'principal'))) }}
              </span>
            </template>
            <template v-slot:item.all_interest="{item}">
              <span class="trsText--text font-weight-medium text-body-1">
                {{ $currencyWithPrecision(parseFloat(calculateConvertibleSum(item.convertibleList, 'interest'))) }}
              </span>
            </template>
            <template v-slot:item.all_total="{item}">
              <span
                class="trsText--text font-weight-medium text-body-1"
              >
                {{ $currencyWithPrecision(parseFloat(calculateConvertibleSum(item.convertibleList, 'total'))) }}
              </span>
            </template>
            <template
              v-slot:expanded-item="{ headers, item }"
            >
              <td
                :colspan="headers.length"
                class="inner-table"
              >
                <common-trs-data-table
                  v-if="item.convertibleList"
                  :headers="convertibleTermHeader"
                  :items="item.convertibleList"
                  :custom-filter="customFilter"
                  show-expand
                  hide-default-header
                  hide-default-footer
                  :expanded.sync="item.expand"
                  disable-pagination
                >
                  <template v-slot:item="{ expand, isExpanded, item, headers }">
                    <tr>
                      <td
                        :colspan="headers.length"
                      >
                        <v-row class="trsText--text font-weight-medium text-body-1 text-right">
                          <v-col
                            cols="4"
                            class="text-left"
                          >
                            <div class="d-flex ml-1">
                              <div
                                v-if="item.name != 'Total'"
                                class="cursor-pointer  pr-1"
                                @click="expand(!isExpanded)"
                              >
                                <v-icon v-if="!isExpanded">
                                  mdi-chevron-down
                                </v-icon>
                                <v-icon v-else>
                                  mdi-chevron-up
                                </v-icon>
                              </div>
                              <div>
                                <share-term-badge
                                  :type="item.convertible_type"
                                  :show-single="true"
                                />
                              </div>
                              <div
                                class="ml-0"
                              >
                                <span>
                                  {{ item.name }}
                                </span>
                                <div class="trsText--text font-weight-light text-body-2 text--lighten-1 mt-2 pl-1">
                                  {{ item.convertibles.length }} {{ item.convertibles.length === 1 ?'Holder':'Holders' }}
                                </div>
                              </div>
                            </div>
                          </v-col>

                          <v-col
                            cols="2"
                          >
                            {{ item.matures_on }}
                          </v-col>
                          <v-col
                            cols="2"
                            class="pr-7"
                          >
                            {{ $currencyWithPrecision(parseFloat(getAmountInThousands(calculateSum(item.convertibles, 'principal')))) }}
                          </v-col>
                          <v-col
                            cols="2"
                            class="pr-5"
                          >
                            {{ $currencyWithPrecision(parseFloat(getAmountInThousands(calculateSum(item.convertibles, 'interest')))) }}
                          </v-col>
                          <v-col
                            cols="2"
                          >
                            {{ $currencyWithPrecision(parseFloat(getAmountInThousands(calculateSum(item.convertibles, 'total')))) }}
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </template>

                  <template v-slot:expanded-item="{item, headers}">
                    <tr
                      class="cursor-pointer"
                    >
                      <td
                        :colspan="headers.length"
                      >
                        <v-row
                          v-for="(obj,key) in item.convertibles"
                          :key="key"
                          class="trsText--text text-body-2 text-right"
                          @click="navigateToDetailsPage(item.id, CONSTS.SHARE_TERM_TYPES.CONVERTIBLE, obj.holder)"
                        >
                          <v-col
                            offset="1"
                            cols="3"
                            class="text-left"
                          >
                            <span>{{ obj.holder.name }}</span>
                          </v-col>
                          <v-col
                            cols="2"
                            class="text-center"
                          >
                            {{ $date(obj.matures_on) }}
                          </v-col>
                          <v-col
                            cols="2"
                            class="pr-7"
                          >
                            {{ $currencyWithPrecision(getAmountInThousands(obj.principal)) }}
                          </v-col>
                          <v-col
                            cols="2"
                            class="pr-5"
                          >
                            {{ $currencyWithPrecision(getAmountInThousands(obj.interest)) }}
                          </v-col>
                          <v-col
                            cols="2"
                          >
                            {{ $currencyWithPrecision(getAmountInThousands(obj.total)) }}
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </template>
                </common-trs-data-table>
              </td>
            </template>
            <!-- Total Row -->
            <template v-slot:body.append="{headers, items}">
              <tr>
                <td
                  :colspan="headers.length"
                  class="px-2 footer-bg"
                >
                  <v-row
                    class="trsText--text font-weight-bold text-body-1 text-right no-gutters"
                  >
                    <v-col
                      cols="4"
                      class="text-left pl-12"
                    >
                      Total
                    </v-col>
                    <v-col
                      cols="2"
                    />
                    <v-col
                      cols="2"
                      class="pr-7"
                    >
                      <span>
                        {{ $currencyWithPrecision(calculateConvertibleSum(items, 'principal', true)) }}
                      </span>
                    </v-col>
                    <v-col
                      cols="2"
                      class="pr-5"
                    >
                      <span>
                        {{ $currencyWithPrecision(calculateConvertibleSum(items, 'interest', true)) }}
                      </span>
                    </v-col>
                    <v-col
                      cols="2"
                      class="pr-2"
                    >
                      <span>
                        {{ $currencyWithPrecision(calculateConvertibleSum(items, 'total', true)) }}
                      </span>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </template>
          </common-trs-data-table>
          <!-- EIP Section -->
          <common-trs-data-table
            v-if="shareItem.optionList && optionFilterApplied && showOthersEip(shareItem)"
            :headers="summaryViewHeaders"
            :items="shareItem.optionList"
            :custom-filter="customFilter"
            show-expand
            class="trs-table-container-card pa-5"
            hide-default-footer
            :expanded.sync="eipExpanded"
            disable-pagination
          >
            <template v-slot:item.name="{item}">
              <span class="trsText--text font-weight-medium text-body-1">
                {{ item.name }}
              </span>
            </template>
            <template v-slot:item.number_outstanding="{item}">
              <span class="trsText--text font-weight-medium text-body-1">
                {{ $number(parseFloat(calculateEipSum([item], 'number_outstanding'))) }}
              </span>
            </template>
            <template v-slot:item.ratio_outstanding="{item}">
              <span class="trsText--text font-weight-medium text-body-1">
                {{ (calculateEipSum([item], 'ratio_outstanding') * 100).toFixed(2) }} %
              </span>
            </template>
            <template v-slot:item.fully_diluted="{item}">
              <span class="trsText--text font-weight-medium text-body-1">
                {{ $number(parseFloat(calculateEipSum([item], 'number_fully_diluted'))) }}
              </span>
            </template>
            <template v-slot:item.fully_diluted_ratio="{item}">
              <span class="trsText--text font-weight-medium text-body-1">
                {{ (calculateEipSum([item], 'ratio_fully_diluted') * 100 ).toFixed(2) }} %
              </span>
            </template>
            <template
              v-slot:expanded-item="{ headers, item }"
            >
              <td
                :colspan="headers.length"
                class="inner-table"
              >
                <!-- Share Terms Section -->
                <common-trs-data-table
                  v-if="(item.share_terms && item.share_terms.length > 0) && (commonFilterApplied || preferredFilterApplied)"
                  :headers="shareTermHeaders"
                  :items="item.share_terms"
                  :custom-filter="customFilter"
                  show-expand
                  hide-default-header
                  hide-default-footer
                  :expanded.sync="item.expand"
                  disable-pagination
                >
                  <template v-slot:item="{ expand, isExpanded, item }">
                    <tr>
                      <td
                        :colspan="headers.length"
                      >
                        <v-row class="trsText--text font-weight-medium text-body-1 text-right">
                          <v-col
                            cols="4"
                            class="text-left"
                          >
                            <div class="d-flex ml-1">
                              <div
                                class="cursor-pointer pr-1"
                                @click="expand(!isExpanded)"
                              >
                                <v-icon v-if="!isExpanded">
                                  mdi-chevron-down
                                </v-icon>
                                <v-icon v-else>
                                  mdi-chevron-up
                                </v-icon>
                              </div>
                              <div
                                v-for="(shareTerm, key) in shareTermList"
                                :key="key"
                              >
                                <share-term-badge
                                  v-if="shareTerm.id === item.share_terms_id"
                                  :type="shareTerm.type"
                                  :show-single="true"
                                />
                              </div>
                              <div
                                class="ml-0"
                              >
                                <span>
                                  {{ item.share_terms_name }}
                                </span>
                                <div class="trsText--text font-weight-light text-body-2 text--lighten-1 mt-2 pl-1">
                                  {{ item.shares.length }} {{ item.shares.length === 1 ?'Holder':'Holders' }}
                                </div>
                              </div>
                            </div>
                          </v-col>

                          <v-col
                            cols="2"
                            class="pr-0"
                          >
                            {{ $number(item.number_outstanding) }}
                          </v-col>
                          <v-col cols="2">
                            {{ (item.ratio_outstanding*100).toFixed(2) }} %
                          </v-col>
                          <v-col
                            cols="2"
                            class="pr-0"
                          >
                            {{ $number(item.number_fully_diluted) }}
                          </v-col>
                          <v-col
                            cols="2"
                          >
                            {{ (item.ratio_fully_diluted*100).toFixed(2) }} %
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </template>

                  <template v-slot:expanded-item="{item}">
                    <tr
                      class="cursor-pointer"
                    >
                      <td
                        :colspan="headers.length"
                      >
                        <v-row
                          v-for="(obj,key) in item.shares"
                          :key="key"
                          class="trsText--text text-body-2 text-right"
                          @click="navigateToDetailsPage(item.share_terms_id, item.share_terms_type, obj.holder)"
                        >
                          <v-col
                            offset="1"
                            cols="3"
                            class="text-left"
                          >
                            <span>{{ obj.holder.name }}</span>
                          </v-col>
                          <v-col
                            cols="2"
                          >
                            {{ $number(obj.number_outstanding) }}
                          </v-col>
                          <v-col cols="2">
                            {{ (obj.ratio_outstanding*100).toFixed(2) }}%
                          </v-col>
                          <v-col
                            cols="2"
                            class="pr-0"
                          >
                            {{ $number(obj.number_fully_diluted) }}
                          </v-col>
                          <v-col
                            cols="2"
                          >
                            {{ (obj.ratio_fully_diluted*100).toFixed(2) }}%
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </template>
                </common-trs-data-table>
                <!-- Options Section -->
                <common-trs-data-table
                  v-if="item.optionList && item.optionList.length > 0"
                  :headers="shareTermHeaders"
                  :items="item.optionList"
                  :custom-filter="customFilter"
                  show-expand
                  hide-default-header
                  hide-default-footer
                  :expanded.sync="item.expanded"
                  disable-pagination
                >
                  <template v-slot:item="{ expand, isExpanded, item }">
                    <tr>
                      <td
                        :colspan="headers.length"
                      >
                        <v-row class="trsText--text font-weight-medium text-body-1 text-right">
                          <v-col
                            cols="4"
                            class="text-left"
                          >
                            <div class="d-flex ml-1">
                              <div
                                v-if="item.name != 'Total'"
                                class="cursor-pointer pr-1"
                                @click="expand(!isExpanded)"
                              >
                                <v-icon v-if="!isExpanded">
                                  mdi-chevron-down
                                </v-icon>
                                <v-icon v-else>
                                  mdi-chevron-up
                                </v-icon>
                              </div>
                              <div>
                                <share-term-badge
                                  :type="CONSTS.OPTIONS_TYPE.ISO"
                                  :show-single="true"
                                />
                              </div>
                              <div
                                class="ml-0"
                              >
                                <span>
                                  Options
                                </span>
                                <div class="trsText--text font-weight-light text-body-2 text--lighten-1 mt-2 pl-1">
                                  {{ item.options.length }} {{ item.options.length === 1 ?'Holder':'Holders' }}
                                </div>
                              </div>
                            </div>
                          </v-col>

                          <v-col
                            cols="2"
                          >
                            NA
                          </v-col>
                          <v-col cols="2">
                            NA
                          </v-col>
                          <v-col
                            cols="2"
                            class="pr-0"
                          >
                            {{ $number(item.number_fully_diluted) }}
                          </v-col>
                          <v-col
                            cols="2"
                          >
                            {{ (item.ratio_fully_diluted*100).toFixed(2) }}%
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </template>

                  <template v-slot:expanded-item="{item}">
                    <tr>
                      <td
                        :colspan="headers.length"
                      >
                        <v-row
                          v-for="(obj,key) in item.options"
                          :key="key"
                          class="trsText--text text-body-2 text-right"
                        >
                          <v-col
                            offset="1"
                            cols="3"
                            class="text-left trsText--text text-body-2 cursor-pointer"
                            @click="navigateToDetailsPage(item.id, CONSTS.SHARE_TERM_TYPES.OPTION, obj.holder)"
                          >
                            <span>{{ obj.holder.name }}</span>
                          </v-col>
                          <v-col
                            cols="2"
                          >
                            NA
                          </v-col>
                          <v-col cols="2">
                            NA
                          </v-col>
                          <v-col
                            cols="2"
                            class="pr-0"
                          >
                            {{ $number(obj.number_fully_diluted) }}
                          </v-col>
                          <v-col
                            cols="2"
                          >
                            {{ (obj.ratio_fully_diluted*100).toFixed(2) }}%
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                    <tr>
                      <td
                        :colspan="headers.length"
                      >
                        <v-row
                          class="trsText--text text-body-2 text-right font-weight-bold"
                        >
                          <v-col
                            offset="1"
                            cols="3"
                            class="text-left"
                          >
                            Equity available for issuance
                          </v-col>
                          <v-col
                            cols="2"
                          >
                            NA
                          </v-col>
                          <v-col cols="2">
                            NA
                          </v-col>
                          <v-col
                            cols="2"
                            class="pr-0"
                          >
                            {{ $number(item.equity_available_for_issuance.number_fully_diluted) }}
                          </v-col>
                          <v-col
                            cols="2"
                            class="pr-0"
                          >
                            {{ (item.equity_available_for_issuance.ratio_fully_diluted*100).toFixed(2) }}%
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </template>
                </common-trs-data-table>
              </td>
            </template>
            <!-- Total Row -->
            <template v-slot:body.append="{ headers, items }">
              <tr>
                <td
                  :colspan="headers.length"
                  class="px-2 footer-bg"
                >
                  <v-row
                    class="trsText--text font-weight-bold text-body-1 text-right no-gutters"
                  >
                    <v-col
                      cols="4"
                      class="text-left pl-12"
                    >
                      Total
                    </v-col>
                    <v-col
                      cols="2"
                    >
                      <span class="mr-m2">
                        {{ $number(parseFloat(calculateEipSum(items, 'number_outstanding'))) }}
                      </span>
                    </v-col>
                    <v-col
                      cols="2"
                      class="pr-2"
                    >
                      <span>
                        {{ (calculateEipSum(items, 'ratio_outstanding') * 100).toFixed(2) }} %
                      </span>
                    </v-col>
                    <v-col
                      cols="2"
                    >
                      <span class="mr-m2">
                        {{ $number(parseFloat(calculateEipSum(items, 'number_fully_diluted'))) }}
                      </span>
                    </v-col>
                    <v-col
                      cols="2"
                      class="pr-2"
                    >
                      <span>
                        {{ (calculateEipSum(items, 'ratio_fully_diluted') * 100 ).toFixed(2) }} %
                      </span>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </template>
          </common-trs-data-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
  import ShareTermBadge from '@/components/common/captable/components/ShareTermBadge'

  export default {
    name: 'CaptableSummaryNonFinancing',

    components: {
      ShareTermBadge,
    },

    props: {
      shareTermHeaders: {
        type: Array,
        required: true,
      },
      convertibleShareHeader: {
        type: Array,
        required: true,
      },
      convertibleTermHeader: {
        type: Array,
        required: true,
      },
      summaryViewHeaders: {
        type: Array,
        required: true,
      },
      summaryData: {
        type: Object,
        required: true,
      },
      search: {
        type: String,
        required: true,
      },
      commonFilterApplied: {
        type: Boolean,
        required: true,
      },
      preferredFilterApplied: {
        type: Boolean,
        required: true,
      },
      convertibleFilterApplied: {
        type: Boolean,
        required: true,
      },
      optionFilterApplied: {
        type: Boolean,
        required: true,
      },
      shareTermList: {
        type: Array,
        required: true,
      },
      warrantTermList: {
        type: Array,
        required: true,
      },
      convertibleTermList: {
        type: Array,
        required: true,
      },
      isHolder: {
        type: Boolean,
        default: false,
      },
      customFilter: {
        type: Function,
        required: true,
      },
      calculateSum: {
        type: Function,
        required: true,
      },
      calculateEipSum: {
        type: Function,
        required: true,
      },
    },

    data: () => ({
      filteredSummaryData: [],
      convertiblesExpanded: [],
      eipExpanded: [],
      orgSummaryData: [],
    }),

    computed: {
      showCommonShares () {
        return this.commonFilterApplied
      },
      showPrefShares () {
        return this.preferredFilterApplied
      },
      showConvertShares () {
        return this.convertibleFilterApplied
      },
      showOptions () {
        return this.optionFilterApplied
      },
    },

    watch: {
      summaryData: {
        handler (val) {
          if (val && Object.keys(val).length > 0) {
            this.filteredSummaryData = []
            this.getSecurityTermList(val)
          }
        },
        immediate: true,
      },
      search: {
        handler (val) {
          if (val) {
            this.updateSearchFilter(val)
          } else {
            this.filteredSummaryData = JSON.parse(JSON.stringify(this.orgSummaryData))
          }
        },
        immediate: true,
      },
    },

    methods: {
      showCommonList (shareItem) {
        return (this.showCommonShares && shareItem?.title?.toLowerCase().indexOf(this.CONSTS.SHARE_TERM_TYPES.COMMON) > -1)
      },
      showPreferredList (shareItem) {
        return (this.showPrefShares && shareItem?.title?.toLowerCase().indexOf(this.CONSTS.SHARE_TERM_TYPES.PREFERRED) > -1)
      },
      showOthersConvertible (shareItem) {
        return (this.showConvertShares && shareItem?.subTitle?.toLowerCase().indexOf(this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE) > -1)
      },
      showOthersEip (shareItem) {
        return (this.showOptions && shareItem?.subTitle?.toLowerCase().indexOf('equity') > -1)
      },
      getSecurityTermList (updatedData) {
        const commonList = JSON.parse(JSON.stringify(updatedData.sharesData[0].shareList))
        const preferredList = JSON.parse(JSON.stringify(updatedData.sharesData[1].shareList))
        const commonShares = this.getSecurityList(commonList, 'share_terms')
        const commonWarrants = this.getSecurityList(commonList, 'warrant_terms')
        const mergedCommonShare = this.getUpdatedSecurityList(commonShares, commonWarrants)

        const preferredShares = this.getSecurityList(preferredList, 'share_terms')
        const preferredWarrants = this.getSecurityList(preferredList, 'warrant_terms')
        const mergedPreferredShare = this.getUpdatedSecurityList(preferredShares, preferredWarrants)

        const allConvertibles = this.getSecurityList(updatedData.othersData.convertibleList, 'convertible_terms')
        const safeConvertibles = allConvertibles.filter(term => term.type === this.CONSTS.CONVERTIBLE_TERM_TYPES.SAFE)
        const debtConvertibles = allConvertibles.filter((term) => term.type === this.CONSTS.CONVERTIBLE_TERM_TYPES.DEBT)
        const mergedConvertibleList = this.getUpdatedConvertibleList(safeConvertibles, debtConvertibles)

        this.filteredSummaryData = [
          {
            title: 'Common Shares',
            shareList: mergedCommonShare,
          },
          {
            title: 'Preferred Shares',
            shareList: mergedPreferredShare,
          },
          {
            title: 'Other Securities',
            subTitle: 'Convertibles',
            convertibleList: mergedConvertibleList,
          },
          {
            subTitle: 'Equity Incentive Plans',
            optionList: this.summaryData.othersData.optionList,
          },
        ]
        let i = 0
        this.filteredSummaryData.forEach(element => {
          if (element.shareList) {
            element.shareList.forEach(item => {
              item.index = i++
            })
          }
          if (element.convertibleList) {
            element.convertibleList.forEach(item => {
              item.index = i++
            })
          }
        })
        this.orgSummaryData = JSON.parse(JSON.stringify(this.filteredSummaryData))
      },
      getUpdatedSecurityList (shareList, warrantList) {
        let securityList = []
        shareList.forEach((share) => {
          let isNew = false
          let linkedShareTerm = securityList.find(term => term.id === share.share_terms_id)
          if (!linkedShareTerm) {
            linkedShareTerm = JSON.parse(JSON.stringify(this.shareTermList.find(term => term.id === share.share_terms_id)))
            isNew = true
          }
          if (!linkedShareTerm.shareList) {
            linkedShareTerm.shareList = [share]
          }
          if (isNew) {
            securityList.push(linkedShareTerm)
          }
        })
        securityList = this.getUpdatedWarrantList(warrantList, securityList)
        securityList.sort((a, b) => {
          return new Date(a?.board_approved_on) - new Date(b?.board_approved_on)
        })

        return securityList
      },
      getUpdatedWarrantList (warrantList, securityList) {
        for (const warrant of warrantList) {
          let isNew = false
          const linkedWarrantTerm = this.warrantTermList.find(term => term.id === warrant.warrant_terms_id)
          let linkedShareTerm = securityList.find(term => term.id === linkedWarrantTerm.share_terms_id)
          if (!linkedShareTerm) {
            linkedShareTerm = JSON.parse(JSON.stringify(this.shareTermList.find(term => term.id === linkedWarrantTerm.share_terms_id)))
            isNew = true
          }
          if (!linkedShareTerm.warrantList) {
            linkedShareTerm.warrantList = []
          }
          const existingWarrantTerm = linkedShareTerm.warrantList.find(item => item.warrant_terms_id === warrant.warrant_terms_id)
          if (!existingWarrantTerm) {
            linkedShareTerm.warrantList.push(warrant)
          }
          if (isNew) {
            securityList.push(linkedShareTerm)
          }
        }
        return securityList
      },
      getUpdatedConvertibleList (safeConvertibles, debtConvertibles) {
        const tempArr = [...safeConvertibles, ...debtConvertibles]
        const uniqueTerms = [...new Set(tempArr.map(term => term.id))]
        let securityList = []
        uniqueTerms.forEach((termId) => {
          let isNew = false
          const linkedConvertibleDetail = this.convertibleTermList.find(term => term.id === termId)
          const safeTerms = safeConvertibles.filter(item => item.id === termId)
          const debtTerms = debtConvertibles.filter(item => item.id === termId)
          const newConvertiblesTerm = [...safeTerms, ...debtTerms]
          let updatedList = []

          let linkedShareTerm = securityList.find(term => term.id === linkedConvertibleDetail.share_terms_id)
          if (!linkedShareTerm) {
            linkedShareTerm = this.shareTermList.find(term => term.id === linkedConvertibleDetail.share_terms_id)
            isNew = true
          }
          newConvertiblesTerm.forEach(item => {
            updatedList = [...new Set(updatedList?.concat(item?.convertibles))]
          })
          if (!linkedShareTerm.convertibleList) {
            const orgConvertibles = linkedConvertibleDetail.convertibles || []
            linkedConvertibleDetail.convertibles = this.getMergedHolders(orgConvertibles, updatedList, this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE)
            linkedShareTerm.convertibleList = [linkedConvertibleDetail]
          } else {
            const existingConvTerm = linkedShareTerm.convertibleList.find(convT => convT.id === linkedConvertibleDetail.id)
            if (existingConvTerm) {
              existingConvTerm.convertibles = this.getMergedHolders(existingConvTerm.convertibles, updatedList, this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE)
            } else {
              linkedConvertibleDetail.convertibles = this.getMergedHolders([], updatedList, this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE)
              linkedShareTerm.convertibleList.push(linkedConvertibleDetail)
            }
          }
          if (isNew) {
            securityList.push(linkedShareTerm)
          }
        })
        const commonSecurity = securityList.filter((security) => security.type === this.CONSTS.SHARE_TERM_TYPES.COMMON)
        const preferredSecurity = securityList.filter((security) => security.type === this.CONSTS.SHARE_TERM_TYPES.PREFERRED)
        securityList = [...commonSecurity, ...preferredSecurity]
        return securityList
      },
      getMergedHolders (orgShareList, newShareList, type) {
        const notMatched = []
        for (const newShare of newShareList) {
          let isMatched = false
          for (const orgShare of orgShareList) {
            if (newShare.holder.id === orgShare.holder.id) {
              if (type && type === this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE) {
                orgShare.principal = parseFloat(orgShare.principal) + parseFloat(newShare.principal)
                orgShare.interest = parseFloat(orgShare.interest) + parseFloat(newShare.interest)
                orgShare.total = parseFloat(orgShare.total) + parseFloat(newShare.total)
              } else {
                orgShare.number_fully_diluted = parseFloat(orgShare.number_fully_diluted) + parseFloat(newShare.number_fully_diluted)
                orgShare.number_outstanding = parseFloat(orgShare.number_outstanding) + parseFloat(newShare.number_outstanding)
                orgShare.ratio_fully_diluted = parseFloat(orgShare.ratio_fully_diluted) + parseFloat(newShare.ratio_fully_diluted)
                orgShare.ratio_outstanding = parseFloat(orgShare.ratio_outstanding) + parseFloat(newShare.ratio_outstanding)
              }
              isMatched = true
              break
            }
          }
          if (!isMatched) {
            notMatched.push(newShare)
          }
        }
        if (notMatched && notMatched.length > 0) {
          orgShareList.push(...notMatched)
        }
        return orgShareList
      },
      getSecurityList (list, key) {
        let updatedList = []
        if (list) {
          list.forEach(item => {
            updatedList = [...new Set(updatedList?.concat(item[key]))]
          })
          updatedList.sort((a, b) => {
            return new Date(a?.board_approved_on) - new Date(b?.board_approved_on)
          })
        }
        return updatedList
      },
      calculateConvertibleSum (list, key, isTotal) {
        let total = 0
        list.forEach((item) => {
          if (item.convertibles) {
            total += this.calculateSum(item.convertibles, key)
          } else {
            total += this.calculateConvertibleSum(item.convertibleList, key)
          }
        })
        return isTotal ? total : this.getAmountInThousands(total)
      },
      calculateShareSum (list, key) {
        let total = 0
        list.forEach((item) => {
          if (!item.warrantList || (key === 'number_outstanding' || key === 'ratio_outstanding')) {
            total += this.calculateSum(item.shareList, key)
          } else if (key !== 'number_outstanding' && key !== 'ratio_outstanding') {
            if (item.shareList) {
              total += this.calculateSum([...item.shareList, ...item.warrantList], key)
            } else {
              total += this.calculateSum(item.warrantList, key)
            }
          }
        })
        return total
      },
      navigateToDetailsPage (id, type, holder) {
        this.$store.dispatch('auth/setHolder', { ...holder, id: [holder?.id] })
        this.$router.push({ name: 'Holder.Holdings', params: { id: id, type: type } })
      },
      getAmountInThousands (amount) {
        if (amount > 0) {
          amount = amount / 1000
        }
        return amount
      },
      updateSearchFilter (searchText) {
        this.filteredSummaryData = JSON.parse(JSON.stringify(this.orgSummaryData))
        this.filteredSummaryData.forEach((data) => {
          this.getFilteredSharesData(data, searchText)
          this.getFilteredConvertibleData(data, searchText)
          this.getFilteredEipData(data, searchText)
        })
      },
      getFilteredSharesData (data, searchText) {
        if (data.shareList && data.shareList.length > 0) {
          data.shareList.forEach((shareItem) => {
            const shareList = this.searchDataByName(searchText, shareItem.shareList, 'shares')
            const warrantList = this.searchDataByName(searchText, shareItem.warrantList, 'warrants')
            if (shareList?.length > 0) {
              shareItem.shareList = shareList
            }
            if (warrantList?.length > 0) {
              shareItem.warrantList = warrantList
            }
          })
        }
      },
      getFilteredConvertibleData (data, searchText) {
        if (data.convertibleList && data.convertibleList.length > 0) {
          data.convertibleList.forEach((convertibleItem) => {
            const convertibleData = this.searchDataByName(searchText, convertibleItem.convertibleList, 'convertibles')
            if (convertibleData?.length > 0) {
              convertibleItem.convertibleList = convertibleData
            }
          })
        }
      },
      getFilteredEipData (data, searchText) {
        if (data.optionList && data.optionList.length > 0) {
          data.optionList.forEach((eipItem) => {
            const eipData = this.searchDataByName(searchText, eipItem.optionList, 'options')
            const sharesList = this.searchDataByName(searchText, eipItem.share_terms, 'shares')
            if (eipData?.length > 0) {
              eipItem.optionList = eipData
            }
            if (eipData?.length > 0) {
              eipItem.share_terms = sharesList
            }
          })
        }
      },
      searchDataByName (searchText, list, subListKey) {
        if (list?.length > 0) {
          list.forEach((item) => {
            item[subListKey] = item[subListKey].filter((item) => {
              if (item?.holder?.name?.toLowerCase().indexOf(searchText.toLowerCase()) > -1) {
                return item
              }
            })
          })
          list = this.removeDataWithoutHolder(list, subListKey)
        }
        return list
      },
      removeDataWithoutHolder (list, subListKey) {
        return list.filter((item) => {
          if (item[subListKey]?.length > 0) {
            return item[subListKey]
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .footer-bg {
    background: var(--v-accent-base);
  }
  .inner-table {
    padding: 0 !important;
    overflow-x: hidden;
  }
  .mr-m2 {
    margin-right: -2px;
  }
</style>
