<template>
  <common-trs-page-container>
    <template v-slot:page-content>
      <common-app-page-header-v2
        title="Cap Tables"
        icon="mdi-calendar-month"
        :padding="0"
      />
      <common-trs-card class="pa-2 pb-0 mb-2 mt-3">
        <v-tabs
          v-model="currentTab"
        >
          <v-tab
            key="summary"
          >
            Summary View
          </v-tab>
          <v-tab
            key="holders"
          >
            Holder's View
          </v-tab>
        </v-tabs>
      </common-trs-card>
      <v-tabs-items
        v-model="currentTab"
        class="transparent-bg"
      >
        <v-tab-item
          key="summary"
          :eager="true"
        >
          <captable-summary-view />
        </v-tab-item>
        <v-tab-item
          key="holders"
          :eager="true"
        >
          <captable-holders-view />
        </v-tab-item>
      </v-tabs-items>
    </template>
  </common-trs-page-container>
</template>

<script>
  import CaptableHoldersView from './components/IssuerCaptableHoldersView'
  import CaptableSummaryView from './components/IssuerCaptableSummary'
  export default {
    name: 'CaptableTabs',

    components: {
      CaptableHoldersView,
      CaptableSummaryView,
    },

    props: {
    },

    data: () => ({
      reloadAll: false,
      currentTab: null,
      count: {
        pending: 0,
        all: 0,
      },
    }),

    computed: {
      preFilters () {
        return {
          pending_countersign: true,
        }
      },
      isDetailedView () {
        return this.currentTab === 1
      },
    },
    watch: {
    },
    created () {
      this.currentTab = this.$route.query.tab ? parseInt(this.$route.query.tab) : 0
    },
    methods: {
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep .v-tab {
    padding: 0px 15px;
    justify-content: start;
    text-transform: initial;
    &:hover, &:active, &:focus {
      background: transparent !important;
    }
  }

</style>
