var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-n5"},_vm._l((_vm.filteredSummaryData),function(shareItem,key){return _c('div',{key:key},[(_vm.showCommonList(shareItem) || _vm.showPreferredList(shareItem) || _vm.showOthersConvertible(shareItem) || _vm.showOthersEip(shareItem))?_c('v-row',[(shareItem.title)?_c('v-col',{staticClass:"mx-2 trsText--text text-h4 font-weight-medium",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(shareItem.title)+" ")]):_vm._e(),(shareItem.subTitle)?_c('v-col',{staticClass:"mx-2 trsText--text text-body-1 py-0",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(shareItem.subTitle)+" ")]):_vm._e(),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[(!shareItem.convertibleList && !shareItem.optionList)?_c('common-trs-data-table',{staticClass:"trs-table-container-card pa-5",attrs:{"headers":_vm.summaryViewHeaders,"items":shareItem.shareList,"custom-filter":_vm.customFilter,"show-expand":"","hide-default-footer":"","expanded":shareItem.expanded,"disable-pagination":"","item-key":"index"},on:{"update:expanded":function($event){return _vm.$set(shareItem, "expanded", $event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.number_outstanding",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" "+_vm._s(item.shareList ? _vm.$number(parseFloat(_vm.calculateSum(item.shareList, 'number_outstanding'))) : 'NA')+" ")])]}},{key:"item.ratio_outstanding",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" "+_vm._s(item.shareList ? (((_vm.calculateSum(item.shareList, 'ratio_outstanding')* 100).toFixed(2)) + " %") : 'NA')+" ")])]}},{key:"item.fully_diluted",fn:function(ref){
var item = ref.item;
return [(!item.warrantList || item.warrantList.length === 0)?_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" "+_vm._s(_vm.$number(parseFloat(_vm.calculateSum(item.shareList, 'number_fully_diluted'))))+" ")]):_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" "+_vm._s(item.shareList ? _vm.$number(parseFloat(_vm.calculateSum(item.shareList.concat( item.warrantList), 'number_fully_diluted'))) : _vm.$number(parseFloat(_vm.calculateSum(item.warrantList, 'number_fully_diluted'))))+" ")])]}},{key:"item.fully_diluted_ratio",fn:function(ref){
var item = ref.item;
return [(!item.warrantList || item.warrantList.length === 0)?_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" "+_vm._s((_vm.calculateSum(item.shareList, 'ratio_fully_diluted')* 100).toFixed(2))+" % ")]):_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" "+_vm._s(item.shareList ? (_vm.calculateSum(item.shareList.concat( item.warrantList), 'ratio_fully_diluted')* 100).toFixed(2) : (_vm.calculateSum(item.warrantList, 'ratio_fully_diluted')* 100).toFixed(2))+" % ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"inner-table",attrs:{"colspan":headers.length}},[((item.shareList && item.shareList.length > 0))?_c('common-trs-data-table',{attrs:{"headers":_vm.shareTermHeaders,"items":item.shareList,"custom-filter":_vm.customFilter,"show-expand":"","hide-default-header":"","hide-default-footer":"","expanded":item.expanded,"disable-pagination":""},on:{"update:expanded":function($event){return _vm.$set(item, "expanded", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
var item = ref.item;
var headers = ref.headers;
return [_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"trsText--text font-weight-medium text-body-1 text-right"},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex ml-1"},[(item.name != 'Total')?_c('div',{staticClass:"cursor-pointer pr-1",on:{"click":function($event){return expand(!isExpanded)}}},[(!isExpanded)?_c('v-icon',[_vm._v(" mdi-chevron-down ")]):_c('v-icon',[_vm._v(" mdi-chevron-up ")])],1):_vm._e(),_vm._l((_vm.shareTermList),function(shareTerm,key){return _c('div',{key:key},[(shareTerm.id === item.share_terms_id)?_c('share-term-badge',{attrs:{"type":shareTerm.type,"show-single":true}}):_vm._e()],1)}),_c('div',{staticClass:"ml-0"},[_c('span',[_vm._v(" "+_vm._s(item.share_terms_name)+" ")]),_c('div',{staticClass:"trsText--text font-weight-light text-body-2 text--lighten-1 mt-2"},[_vm._v(" "+_vm._s(item.shares.length)+" "+_vm._s(item.shares.length === 1 ?'Holder':'Holders')+" ")])])],2)]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$number(item.number_outstanding))+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s((item.ratio_outstanding*100).toFixed(2))+" % ")]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$number(item.number_fully_diluted))+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s((item.ratio_fully_diluted*100).toFixed(2))+" % ")])],1)],1)])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('tr',{staticClass:"cursor-pointer"},[_c('td',{attrs:{"colspan":headers.length}},_vm._l((item.shares),function(obj,key){return _c('v-row',{key:key,staticClass:"trsText--text text-body-2 text-right",on:{"click":function($event){return _vm.navigateToDetailsPage(item.share_terms_id, item.share_terms_type, obj.holder)}}},[_c('v-col',{staticClass:"text-left",attrs:{"offset":"1","cols":"3"}},[_c('span',[_vm._v(_vm._s(obj.holder.name))])]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$number(obj.number_outstanding))+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s((obj.ratio_outstanding*100).toFixed(2))+"% ")]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$number(obj.number_fully_diluted))+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s((obj.ratio_fully_diluted*100).toFixed(2))+"% ")])],1)}),1)])]}}],null,true)}):_vm._e(),(item.warrantList && item.warrantList.length > 0)?_c('common-trs-data-table',{attrs:{"headers":_vm.shareTermHeaders,"items":item.warrantList,"custom-filter":_vm.customFilter,"show-expand":"","hide-default-header":"","hide-default-footer":"","expanded":item.expand,"disable-pagination":""},on:{"update:expanded":function($event){return _vm.$set(item, "expand", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
var item = ref.item;
var headers = ref.headers;
return [_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"trsText--text font-weight-medium text-body-1 text-right"},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex ml-1"},[(item.name != 'Total')?_c('div',{staticClass:"cursor-pointer pr-1",on:{"click":function($event){return expand(!isExpanded)}}},[(!isExpanded)?_c('v-icon',[_vm._v(" mdi-chevron-down ")]):_c('v-icon',[_vm._v(" mdi-chevron-up ")])],1):_vm._e(),_vm._l((_vm.warrantTermList),function(term,key){return _c('div',{key:key},[(term.id === item.warrant_terms_id)?_c('share-term-badge',{attrs:{"type":_vm.CONSTS.SHARE_TERM_TYPES.WARRANT,"show-single":true}}):_vm._e()],1)}),_c('div',{staticClass:"ml-0"},[_c('span',[_vm._v(" "+_vm._s(item.warrant_terms_name)+" ")]),_c('div',{staticClass:"trsText--text font-weight-light text-body-2 text--lighten-1 mt-2 pl-1"},[_vm._v(" "+_vm._s(item.warrants.length)+" "+_vm._s(item.warrants.length === 1 ?'Holder':'Holders')+" ")])])],2)]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" NA ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" NA ")]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$number(item.number_fully_diluted))+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s((item.ratio_fully_diluted*100).toFixed(2))+"% ")])],1)],1)])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('tr',{staticClass:"cursor-pointer"},[_c('td',{attrs:{"colspan":headers.length}},_vm._l((item.warrants),function(obj,key){return _c('v-row',{key:key,staticClass:"trsText--text text-body-2 text-right",on:{"click":function($event){return _vm.navigateToDetailsPage(item.warrant_terms_id, _vm.CONSTS.SHARE_TERM_TYPES.WARRANT, obj.holder)}}},[_c('v-col',{staticClass:"text-left",attrs:{"offset":"1","cols":"3"}},[_c('span',[_vm._v(_vm._s(obj.holder.name))])]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" NA ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" NA ")]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$number(obj.number_fully_diluted))+" ")]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s((obj.ratio_fully_diluted*100).toFixed(2))+"% ")])],1)}),1)])]}}],null,true)}):_vm._e()],1)]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
var items = ref.items;
return [_c('tr',[_c('td',{staticClass:"px-2 footer-bg",attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"trsText--text font-weight-bold text-body-1 text-right no-gutters"},[_c('v-col',{staticClass:"text-left pl-12",attrs:{"cols":"4"}},[_vm._v(" Total ")]),_c('v-col',{attrs:{"cols":"2"}},[_c('span',{staticClass:"mr-m2"},[_vm._v(" "+_vm._s(_vm.$number(parseFloat(_vm.calculateShareSum(items, 'number_outstanding'))))+" ")])]),_c('v-col',{staticClass:"pr-2",attrs:{"cols":"2"}},[_c('span',[_vm._v(" "+_vm._s((_vm.calculateShareSum(items, 'ratio_outstanding') * 100).toFixed(2))+" % ")])]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_c('span',{staticClass:"mr-m2"},[_vm._v(" "+_vm._s(_vm.$number(parseFloat(_vm.calculateShareSum(items, 'number_fully_diluted'))))+" ")])]),_c('v-col',{staticClass:"pr-2",attrs:{"cols":"2"}},[_c('span',[_vm._v(" "+_vm._s((_vm.calculateShareSum(items, 'ratio_fully_diluted') * 100 ).toFixed(2))+" % ")])])],1)],1)])]}}],null,true)}):_vm._e(),(shareItem.convertibleList && _vm.showOthersConvertible(shareItem))?_c('common-trs-data-table',{staticClass:"trs-table-container-card pa-5",attrs:{"headers":_vm.convertibleShareHeader,"items":shareItem.convertibleList,"custom-filter":_vm.customFilter,"show-expand":"","hide-default-footer":"","expanded":_vm.convertiblesExpanded,"disable-pagination":"","item-key":"index"},on:{"update:expanded":function($event){_vm.convertiblesExpanded=$event}},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"trs--text text-body-1 font-weight-medium"},[_vm._v(" "+_vm._s(header.text))])]}},{key:"header.maturity",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"trs--text text-body-1 font-weight-medium"},[_vm._v(" "+_vm._s(header.text))])]}},{key:"header.all_principal",fn:function(ref){
var header = ref.header;
return [_c('span',[_c('div',{staticClass:"trs--text text-body-1 font-weight-medium"},[_vm._v(" "+_vm._s(header.text))]),_c('span',{staticClass:"trsText--text text-body-2 mt-1"},[_vm._v(" "+_vm._s(header.text2)+" ")])])]}},{key:"header.all_interest",fn:function(ref){
var header = ref.header;
return [_c('span',[_c('div',{staticClass:"trs--text text-body-1 font-weight-medium"},[_vm._v(" "+_vm._s(header.text))]),_c('span',{staticClass:"trsText--text text-body-2 mt-1"},[_vm._v(" "+_vm._s(header.text2)+" ")])])]}},{key:"header.all_total",fn:function(ref){
var header = ref.header;
return [_c('span',[_c('div',{staticClass:"trs--text text-body-1 font-weight-medium"},[_vm._v(" "+_vm._s(header.text))]),_c('span',{staticClass:"trsText--text text-body-2 mt-1"},[_vm._v(" "+_vm._s(header.text2)+" ")])])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.all_principal",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(parseFloat(_vm.calculateConvertibleSum(item.convertibleList, 'principal'))))+" ")])]}},{key:"item.all_interest",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(parseFloat(_vm.calculateConvertibleSum(item.convertibleList, 'interest'))))+" ")])]}},{key:"item.all_total",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(parseFloat(_vm.calculateConvertibleSum(item.convertibleList, 'total'))))+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"inner-table",attrs:{"colspan":headers.length}},[(item.convertibleList)?_c('common-trs-data-table',{attrs:{"headers":_vm.convertibleTermHeader,"items":item.convertibleList,"custom-filter":_vm.customFilter,"show-expand":"","hide-default-header":"","hide-default-footer":"","expanded":item.expand,"disable-pagination":""},on:{"update:expanded":function($event){return _vm.$set(item, "expand", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
var item = ref.item;
var headers = ref.headers;
return [_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"trsText--text font-weight-medium text-body-1 text-right"},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex ml-1"},[(item.name != 'Total')?_c('div',{staticClass:"cursor-pointer  pr-1",on:{"click":function($event){return expand(!isExpanded)}}},[(!isExpanded)?_c('v-icon',[_vm._v(" mdi-chevron-down ")]):_c('v-icon',[_vm._v(" mdi-chevron-up ")])],1):_vm._e(),_c('div',[_c('share-term-badge',{attrs:{"type":item.convertible_type,"show-single":true}})],1),_c('div',{staticClass:"ml-0"},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"trsText--text font-weight-light text-body-2 text--lighten-1 mt-2 pl-1"},[_vm._v(" "+_vm._s(item.convertibles.length)+" "+_vm._s(item.convertibles.length === 1 ?'Holder':'Holders')+" ")])])])]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(item.matures_on)+" ")]),_c('v-col',{staticClass:"pr-7",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(parseFloat(_vm.getAmountInThousands(_vm.calculateSum(item.convertibles, 'principal')))))+" ")]),_c('v-col',{staticClass:"pr-5",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(parseFloat(_vm.getAmountInThousands(_vm.calculateSum(item.convertibles, 'interest')))))+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(parseFloat(_vm.getAmountInThousands(_vm.calculateSum(item.convertibles, 'total')))))+" ")])],1)],1)])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('tr',{staticClass:"cursor-pointer"},[_c('td',{attrs:{"colspan":headers.length}},_vm._l((item.convertibles),function(obj,key){return _c('v-row',{key:key,staticClass:"trsText--text text-body-2 text-right",on:{"click":function($event){return _vm.navigateToDetailsPage(item.id, _vm.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE, obj.holder)}}},[_c('v-col',{staticClass:"text-left",attrs:{"offset":"1","cols":"3"}},[_c('span',[_vm._v(_vm._s(obj.holder.name))])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$date(obj.matures_on))+" ")]),_c('v-col',{staticClass:"pr-7",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(_vm.getAmountInThousands(obj.principal)))+" ")]),_c('v-col',{staticClass:"pr-5",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(_vm.getAmountInThousands(obj.interest)))+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(_vm.getAmountInThousands(obj.total)))+" ")])],1)}),1)])]}}],null,true)}):_vm._e()],1)]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
var items = ref.items;
return [_c('tr',[_c('td',{staticClass:"px-2 footer-bg",attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"trsText--text font-weight-bold text-body-1 text-right no-gutters"},[_c('v-col',{staticClass:"text-left pl-12",attrs:{"cols":"4"}},[_vm._v(" Total ")]),_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{staticClass:"pr-7",attrs:{"cols":"2"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(_vm.calculateConvertibleSum(items, 'principal', true)))+" ")])]),_c('v-col',{staticClass:"pr-5",attrs:{"cols":"2"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(_vm.calculateConvertibleSum(items, 'interest', true)))+" ")])]),_c('v-col',{staticClass:"pr-2",attrs:{"cols":"2"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$currencyWithPrecision(_vm.calculateConvertibleSum(items, 'total', true)))+" ")])])],1)],1)])]}}],null,true)}):_vm._e(),(shareItem.optionList && _vm.optionFilterApplied && _vm.showOthersEip(shareItem))?_c('common-trs-data-table',{staticClass:"trs-table-container-card pa-5",attrs:{"headers":_vm.summaryViewHeaders,"items":shareItem.optionList,"custom-filter":_vm.customFilter,"show-expand":"","hide-default-footer":"","expanded":_vm.eipExpanded,"disable-pagination":""},on:{"update:expanded":function($event){_vm.eipExpanded=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.number_outstanding",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" "+_vm._s(_vm.$number(parseFloat(_vm.calculateEipSum([item], 'number_outstanding'))))+" ")])]}},{key:"item.ratio_outstanding",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" "+_vm._s((_vm.calculateEipSum([item], 'ratio_outstanding') * 100).toFixed(2))+" % ")])]}},{key:"item.fully_diluted",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" "+_vm._s(_vm.$number(parseFloat(_vm.calculateEipSum([item], 'number_fully_diluted'))))+" ")])]}},{key:"item.fully_diluted_ratio",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"trsText--text font-weight-medium text-body-1"},[_vm._v(" "+_vm._s((_vm.calculateEipSum([item], 'ratio_fully_diluted') * 100 ).toFixed(2))+" % ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"inner-table",attrs:{"colspan":headers.length}},[((item.share_terms && item.share_terms.length > 0) && (_vm.commonFilterApplied || _vm.preferredFilterApplied))?_c('common-trs-data-table',{attrs:{"headers":_vm.shareTermHeaders,"items":item.share_terms,"custom-filter":_vm.customFilter,"show-expand":"","hide-default-header":"","hide-default-footer":"","expanded":item.expand,"disable-pagination":""},on:{"update:expanded":function($event){return _vm.$set(item, "expand", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
var item = ref.item;
return [_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"trsText--text font-weight-medium text-body-1 text-right"},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex ml-1"},[_c('div',{staticClass:"cursor-pointer pr-1",on:{"click":function($event){return expand(!isExpanded)}}},[(!isExpanded)?_c('v-icon',[_vm._v(" mdi-chevron-down ")]):_c('v-icon',[_vm._v(" mdi-chevron-up ")])],1),_vm._l((_vm.shareTermList),function(shareTerm,key){return _c('div',{key:key},[(shareTerm.id === item.share_terms_id)?_c('share-term-badge',{attrs:{"type":shareTerm.type,"show-single":true}}):_vm._e()],1)}),_c('div',{staticClass:"ml-0"},[_c('span',[_vm._v(" "+_vm._s(item.share_terms_name)+" ")]),_c('div',{staticClass:"trsText--text font-weight-light text-body-2 text--lighten-1 mt-2 pl-1"},[_vm._v(" "+_vm._s(item.shares.length)+" "+_vm._s(item.shares.length === 1 ?'Holder':'Holders')+" ")])])],2)]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$number(item.number_outstanding))+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s((item.ratio_outstanding*100).toFixed(2))+" % ")]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$number(item.number_fully_diluted))+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s((item.ratio_fully_diluted*100).toFixed(2))+" % ")])],1)],1)])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticClass:"cursor-pointer"},[_c('td',{attrs:{"colspan":headers.length}},_vm._l((item.shares),function(obj,key){return _c('v-row',{key:key,staticClass:"trsText--text text-body-2 text-right",on:{"click":function($event){return _vm.navigateToDetailsPage(item.share_terms_id, item.share_terms_type, obj.holder)}}},[_c('v-col',{staticClass:"text-left",attrs:{"offset":"1","cols":"3"}},[_c('span',[_vm._v(_vm._s(obj.holder.name))])]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$number(obj.number_outstanding))+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s((obj.ratio_outstanding*100).toFixed(2))+"% ")]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$number(obj.number_fully_diluted))+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s((obj.ratio_fully_diluted*100).toFixed(2))+"% ")])],1)}),1)])]}}],null,true)}):_vm._e(),(item.optionList && item.optionList.length > 0)?_c('common-trs-data-table',{attrs:{"headers":_vm.shareTermHeaders,"items":item.optionList,"custom-filter":_vm.customFilter,"show-expand":"","hide-default-header":"","hide-default-footer":"","expanded":item.expanded,"disable-pagination":""},on:{"update:expanded":function($event){return _vm.$set(item, "expanded", $event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
var item = ref.item;
return [_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"trsText--text font-weight-medium text-body-1 text-right"},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex ml-1"},[(item.name != 'Total')?_c('div',{staticClass:"cursor-pointer pr-1",on:{"click":function($event){return expand(!isExpanded)}}},[(!isExpanded)?_c('v-icon',[_vm._v(" mdi-chevron-down ")]):_c('v-icon',[_vm._v(" mdi-chevron-up ")])],1):_vm._e(),_c('div',[_c('share-term-badge',{attrs:{"type":_vm.CONSTS.OPTIONS_TYPE.ISO,"show-single":true}})],1),_c('div',{staticClass:"ml-0"},[_c('span',[_vm._v(" Options ")]),_c('div',{staticClass:"trsText--text font-weight-light text-body-2 text--lighten-1 mt-2 pl-1"},[_vm._v(" "+_vm._s(item.options.length)+" "+_vm._s(item.options.length === 1 ?'Holder':'Holders')+" ")])])])]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" NA ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" NA ")]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$number(item.number_fully_diluted))+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s((item.ratio_fully_diluted*100).toFixed(2))+"% ")])],1)],1)])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{attrs:{"colspan":headers.length}},_vm._l((item.options),function(obj,key){return _c('v-row',{key:key,staticClass:"trsText--text text-body-2 text-right"},[_c('v-col',{staticClass:"text-left trsText--text text-body-2 cursor-pointer",attrs:{"offset":"1","cols":"3"},on:{"click":function($event){return _vm.navigateToDetailsPage(item.id, _vm.CONSTS.SHARE_TERM_TYPES.OPTION, obj.holder)}}},[_c('span',[_vm._v(_vm._s(obj.holder.name))])]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" NA ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" NA ")]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$number(obj.number_fully_diluted))+" ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" "+_vm._s((obj.ratio_fully_diluted*100).toFixed(2))+"% ")])],1)}),1)]),_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"trsText--text text-body-2 text-right font-weight-bold"},[_c('v-col',{staticClass:"text-left",attrs:{"offset":"1","cols":"3"}},[_vm._v(" Equity available for issuance ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" NA ")]),_c('v-col',{attrs:{"cols":"2"}},[_vm._v(" NA ")]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(_vm.$number(item.equity_available_for_issuance.number_fully_diluted))+" ")]),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s((item.equity_available_for_issuance.ratio_fully_diluted*100).toFixed(2))+"% ")])],1)],1)])]}}],null,true)}):_vm._e()],1)]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
var items = ref.items;
return [_c('tr',[_c('td',{staticClass:"px-2 footer-bg",attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"trsText--text font-weight-bold text-body-1 text-right no-gutters"},[_c('v-col',{staticClass:"text-left pl-12",attrs:{"cols":"4"}},[_vm._v(" Total ")]),_c('v-col',{attrs:{"cols":"2"}},[_c('span',{staticClass:"mr-m2"},[_vm._v(" "+_vm._s(_vm.$number(parseFloat(_vm.calculateEipSum(items, 'number_outstanding'))))+" ")])]),_c('v-col',{staticClass:"pr-2",attrs:{"cols":"2"}},[_c('span',[_vm._v(" "+_vm._s((_vm.calculateEipSum(items, 'ratio_outstanding') * 100).toFixed(2))+" % ")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('span',{staticClass:"mr-m2"},[_vm._v(" "+_vm._s(_vm.$number(parseFloat(_vm.calculateEipSum(items, 'number_fully_diluted'))))+" ")])]),_c('v-col',{staticClass:"pr-2",attrs:{"cols":"2"}},[_c('span',[_vm._v(" "+_vm._s((_vm.calculateEipSum(items, 'ratio_fully_diluted') * 100 ).toFixed(2))+" % ")])])],1)],1)])]}}],null,true)}):_vm._e()],1)],1):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }