<template>
  <div>
    <search-bar
      v-model="search"
      :label="label"
    >
      <template v-slot:content>
        <v-row class="no-gutters">
          <v-col
            cols="12"
            md="4"
            class="px-1"
          >
            <common-input-select
              v-model="filterText"
              label="Securities"
              placeholder="Select Securities"
              :items="CONSTS.HOLDERS_FILTER_SECURITIES_TYPES"
              item-text="value"
              item-value="id"
              multiple
              @change="updateFilter"
            />
          </v-col>
        </v-row>
      </template>
      <template
        v-slot:actions
      >
        <captable-filters
          v-model="asOfDate"
          @input="updateAsofDate"
        />
      </template>
    </search-bar>
    <div class="mt-n4">
      <common-trs-card>
        <common-trs-data-table
          :headers="holdersViewHeaders"
          :items="holdersData"
          :loading="loading"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.holder`]="{ item }">
            <common-text-truncate
              class="text-body-1 font-weight-normal cursor-pointer primary--text"
              style="height: auto;"
              :line-clamp="1"
              :text="item.holder.name"
              :tooltip="true"
              @click="navigateToHolderView(item)"
            />
          </template>
          <template v-slot:[`item.share_terms_ids`]="{ item }">
            <share-term-badge
              class="mx-1 badge-box-card"
              :type-list="item.securities"
              :badge-list="CONSTS.SECURITIES_PREFIX_LIST"
            />
          </template>
          <template v-slot:[`item.number_outstanding`]="{ item }">
            <span>
              {{ $number(item.number_outstanding) }}
            </span>
          </template>
          <template v-slot:[`item.ratio_outstanding`]="{ item }">
            <span>
              {{ ((item.ratio_outstanding)*100).toFixed(2) }} %
            </span>
          </template>
          <template v-slot:[`item.number_fully_diluted`]="{ item }">
            <span>
              {{ $number(item.number_fully_diluted) }}
            </span>
          </template>
          <template v-slot:[`item.ratio_fully_diluted`]="{ item }">
            <span>
              {{ ((item.ratio_fully_diluted)*100).toFixed(2) }} %

            </span>
          </template>
          <template slot="body.append">
            <tr
              v-if="holdersData"
            >
              <td
                class="trsText--text font-weight-medium text-body-1 text-left"
              >
                Equity available for issuance
              </td>
              <td />
              <td class="trsText--text text-body-1 text-right">
                <span>
                  NA
                </span>
              </td>
              <td class="trsText--text text-body-1 text-right">
                <span>
                  NA
                </span>
              </td>
              <td class="trsText--text text-body-1 text-right">
                <span>
                  {{ $number(equityAvailable.number_fully_diluted) }}
                </span>
              </td>
              <td class="trsText--text text-body-1 text-right">
                <span>
                  {{ (parseFloat(equityAvailable.ratio_fully_diluted === 'NA' ? 0 : equityAvailable.ratio_fully_diluted) * 100 ).toFixed(2) }} %
                </span>
              </td>
            </tr>
            <tr
              v-if="holdersData"
              class=" footer-bg"
            >
              <td
                class="trsText--text font-weight-bold text-body-1 text-left"
              >
                Total
              </td>
              <td />
              <td class="trsText--text font-weight-bold text-body-1 text-right">
                <span>
                  {{ $number(calculateSum('number_outstanding')) }}
                </span>
              </td>
              <td class="trsText--text font-weight-bold text-body-1 text-right">
                <span>
                  {{ (calculateSum('ratio_outstanding') * 100).toFixed(2) }} %
                </span>
              </td>
              <td class="trsText--text font-weight-bold text-body-1 text-right">
                <span>
                  {{ $number(calculateSum('number_fully_diluted')) }}
                </span>
              </td>
              <td class="trsText--text font-weight-bold text-body-1 text-right">
                <span>
                  {{ (calculateSum('ratio_fully_diluted') * 100 ).toFixed(2) }} %
                </span>
              </td>
            </tr>
          </template>
        </common-trs-data-table>
      </common-trs-card>
    </div>
    <!-- Other Securities -->
    <v-row v-if="otherHoldersData && otherHoldersData.length > 0">
      <v-col class="ma-2 mt-5 trsText--text text-h4 font-weight-medium">
        Other Securities
      </v-col>
    </v-row>
    <common-trs-card>
      <common-trs-data-table
        :headers="otherViewHeaders"
        :items="convertibleHoldersData"
        :search="search"
        :loading="loading"
        hide-default-footer
        disable-pagination
      >
        <!-- Headers -->
        <template v-slot:[`header.holder`]="{ header }">
          <span class="trs--text text-body-2"> {{ header.text }}</span>
        </template>
        <template v-slot:[`header.convertible_terms_ids`]="{ header }">
          <span class="trs--text text-body-2"> {{ header.text }}</span>
        </template>
        <template v-slot:[`header.principal`]="{ header }">
          <span>
            <div class="trs--text text-body-2"> {{ header.text }}</div>
            <span class="trsText--text text-body-2 mt-1">
              {{ header.text2 }}
            </span>
          </span>
        </template>
        <template v-slot:[`header.interest`]="{ header }">
          <span>
            <div class="trs--text text-body-2"> {{ header.text }}</div>
            <span class="trsText--text text-body-2 mt-1">
              {{ header.text2 }}
            </span>
          </span>
        </template>
        <template v-slot:[`header.total`]="{ header }">
          <span>
            <div class="trs--text text-body-2"> {{ header.text }}</div>
            <span class="trsText--text text-body-2 mt-1">
              {{ header.text2 }}
            </span>
          </span>
        </template>

        <!-- Body -->
        <template v-slot:[`item.holder`]="{ item }">
          <common-text-truncate
            class="text-body-1 font-weight-normal cursor-pointer primary--text"
            style="height: auto;"
            :line-clamp="1"
            :text="item.holder.name"
            :tooltip="true"
            @click="navigateToHolderView(item)"
          />
        </template>
        <template v-slot:[`item.convertible_terms_ids`]="{ item }">
          <share-term-badge
            class="mx-1 badge-box-card"
            :type-list="item.securities"
            :badge-list="CONSTS.CONVERTIBLE_PREFIX_LIST"
          />
        </template>
        <template v-slot:[`item.principal`]="{ item }">
          <span>
            {{ $currencyWithPrecision(getAmountInThousands(item.principal)) }}
          </span>
        </template>
        <template v-slot:[`item.interest`]="{ item }">
          <span>
            {{ $currencyWithPrecision(getAmountInThousands(item.interest)) }}
          </span>
        </template>
        <template v-slot:[`item.total`]="{ item }">
          <span>
            {{ $currencyWithPrecision(getAmountInThousands(item.total)) }}
          </span>
        </template>
        <template slot="body.append">
          <tr
            v-if="otherHoldersData"
            class=" footer-bg"
          >
            <td
              class="trsText--text font-weight-bold text-body-1 text-left"
            >
              Total
            </td>
            <td />
            <td class="trsText--text font-weight-bold text-body-1 text-right">
              <span>
                {{ $currencyWithPrecision(calculateOthersSum('principal')) }}
              </span>
            </td>
            <td class="trsText--text font-weight-bold text-body-1 text-right">
              <span>
                {{ $currencyWithPrecision(calculateOthersSum('interest')) }}
              </span>
            </td>
            <td class="trsText--text font-weight-bold text-body-1 text-right">
              <span>
                {{ $currencyWithPrecision(calculateOthersSum('total')) }}
              </span>
            </td>
          </tr>
        </template>
      </common-trs-data-table>
    </common-trs-card>
  </div>
</template>
<script>
  import * as captableService from '@/components/common/captable/services/captable'
  import ShareTermBadge from '@/components/common/captable/components/ShareTermBadge'
  import CaptableFilters from './CaptableFilters.vue'
  import moment from 'moment'
  import searchBar from '@/components/common/captable/components/Search'
  import CommonTextTruncate from '@/components/core/common/TruncateText'

  export default {
    name: 'CaptableHoldersView',

    components: {
      ShareTermBadge,
      CaptableFilters,
      searchBar,
      CommonTextTruncate,
    },
    data: () => ({
      items: [],
      shareTermList: [],
      warrantTermList: [],
      cyndxHoldersData: [],
      equityAvailable: {},
      expanded: [],
      singleExpand: false,
      isExpanded: false,
      holdersViewHeaders: [
        { text: 'Holder Name', value: 'holder', sortable: true },
        { text: 'Securities', value: 'share_terms_ids', sortable: true, width: '400' },
        { text: 'Outstanding', value: 'number_outstanding', sortable: false, align: 'right' },
        { text: 'Ownership', value: 'ratio_outstanding', sortable: false, align: 'right', width: '100' },
        { text: 'Fully Diluted', value: 'number_fully_diluted', sortable: false, align: 'right' },
        { text: 'Ownership', value: 'ratio_fully_diluted', sortable: false, align: 'right', width: '100' },
      ],
      loading: false,
      asOfDate: {},
      date: '',
      label: 'Search Holders',
      search: '',
      includeDraft: false,
      convertibleTerms: [],
      otherHoldersData: [],
      otherViewHeaders: [
        { text: 'Holder Name', value: 'holder', sortable: true },
        { text: 'Securities', value: 'convertible_terms_ids', sortable: true },
        { text: 'Principal', text2: '(in Thousands)', value: 'principal', sortable: false, align: 'right' },
        { text: 'Interest', text2: '(in Thousands)', value: 'interest', sortable: false, align: 'right' },
        { text: 'Total', text2: '(in Thousands)', value: 'total', sortable: false, align: 'right' },
      ],
      filterText: [],
      summaryDataForFilter: [],
      holdersData: [],
      convertibleHoldersData: [],
      filter: {},
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
    },
    watch: {
      asOfDate: {
        handler (val) {
          if (val) {
            this.date = val.date
            this.includeDraft = val.include_draft
            this.loadHoldersViewData()
          }
        },
        immediate: true,
        deep: true,
      },
      search: {
        handler (val) {
          if (val) {
            this.updateSearchedHolder(val)
          } else {
            this.holdersData = JSON.parse(JSON.stringify(this.summaryDataForFilter))
            this.convertibleHoldersData = JSON.parse(JSON.stringify(this.otherHoldersData))
          }
        },
        immediate: true,
      },
    },

    async mounted () {
      this.loading = true
      await this.loadHoldersViewData()
      await this.getOtherUpdatedItems()
      this.loading = false
    },

    methods: {
      async loadHoldersViewData () {
        try {
          this.date = this.date ? this.date : moment().format('YYYY-MM-DD')
          this.includeDraft = this.includeDraft ? this.includeDraft : false
          const resp = await captableService.getCaptableHoldersData(this.captableId, this.date, this.includeDraft)
          this.cyndxHoldersData = resp.data.equity_holders
          this.shareTermList = resp.data.share_terms
          this.warrantTermList = resp.data.warrant_terms
          this.equityAvailable = resp.data.equity_available_for_issuance
          this.otherHoldersData = resp.data.convertible_holders
          this.convertibleTerms = resp.data.convertible_terms
          this.getUpdatedItems()
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
      },

      calculateSum (key) {
        let total = 0
        let totalEquity = this.holdersData.reduce((accumulator, currentValue) => {
          (total += +currentValue[key])
          return total
        }, 0)
        if (key === 'number_fully_diluted' || key === 'ratio_fully_diluted') {
          const value = this.equityAvailable[key] === 'NA' ? 0 : this.equityAvailable[key]
          totalEquity += parseFloat(value)
        }
        return totalEquity
      },

      calculateOthersSum (key) {
        let total = 0
        return this.otherHoldersData.reduce((accumulator, currentValue) => {
          (total += +currentValue[key])
          return this.getAmountInThousands(total)
        }, 0)
      },

      getAmountInThousands (amount) {
        if (amount > 0) {
          amount = amount / 1000
        }
        return amount
      },

      calculateTotalShareTermDetails (prop, isLength, isPerc) {
        let total = 0
        this.holdersData.forEach((item) => {
          total += this.calculateShareTermDetails(item, prop, isLength, isPerc)
        })
        return total
      },

      calculateShareTermDetails (item, prop, isLength, isPerc) {
        let total = 0
        if (item.share_terms) {
          item.share_terms.forEach((obj) => {
            if (obj[prop] && prop !== 'shares') {
              total += isLength ? obj[prop].length : parseFloat(obj[prop])
            } else if (obj[prop] && prop === 'shares') {
              const arr = [...new Set(obj[prop].map((i) => i.holder.id))]
              total = arr.length
            }
          })
        }
        return isPerc ? total * 100 : total
      },

      getTermDetails (uniqueTermIds, item, list, type) {
        if (item && uniqueTermIds) {
          item.securities = item.securities || []
          const securities = []
          uniqueTermIds.forEach((id) => {
            list.forEach((data) => {
              if (data.id === id) {
                data.type = type || data.type || data.convertible_type
                securities.push(data)
              }
            })
          })
          item.securities = [...new Set(item.securities.concat(securities))]
        }
      },

      getOtherUpdatedItems () {
        const items = JSON.parse(JSON.stringify(this.otherHoldersData))
        items.forEach((item) => {
          let uniqueTermIds = []
          if (item.convertible_terms_ids && item.convertible_terms_ids.length > 0) {
            uniqueTermIds = [...new Set(item.convertible_terms_ids)]
            this.getTermDetails(uniqueTermIds, item, this.convertibleTerms, null)
          }
        })
        this.convertibleHoldersData = items
      },
      getUpdatedItems () {
        const items = JSON.parse(JSON.stringify(this.cyndxHoldersData))
        items.forEach((item) => {
          let uniqueTermIds = []
          if (item.share_terms_ids && item.share_terms_ids.length > 0) {
            const list = JSON.parse(JSON.stringify(this.shareTermList))
            uniqueTermIds = [...new Set(item.share_terms_ids)]
            this.getTermDetails(uniqueTermIds, item, list, null)
          }
          if (item.warrant_terms_ids && item.warrant_terms_ids.length > 0) {
            const list = JSON.parse(JSON.stringify(this.warrantTermList))
            uniqueTermIds = [...new Set(item.warrant_terms_ids)]
            this.getTermDetails(uniqueTermIds, item, list, this.CONSTS.SHARE_TERM_TYPES.WARRANT)
          }
          if (item.option_share_terms_ids && item.option_share_terms_ids.length > 0) {
            const list = JSON.parse(JSON.stringify(this.shareTermList))
            uniqueTermIds = [...new Set(item.option_share_terms_ids)]
            this.getTermDetails(uniqueTermIds, item, list, this.CONSTS.SHARE_TERM_TYPES.OPTION)
          }
        })
        this.holdersData = this.getDataWithInvestments(this.getSortedHolderList(items))
        this.summaryDataForFilter = JSON.parse(JSON.stringify(this.holdersData))
      },
      updateAsofDate (value) {
        this.asOfDate = Object.assign({}, value) || ''
      },
      getSortedHolderList (list) {
        list.sort((a, b) => {
          const aName = a.holder.name.toLowerCase()
          const bName = b.holder.name.toLowerCase()
          if (aName < bName) { return -1 }
          if (aName > bName) { return 1 }
          return 0
        })
        return list
      },
      getDataWithInvestments (list) {
        const updatedList = []
        list.forEach((item) => {
          if (item.number_fully_diluted > 0 || item.number_outstanding > 0) {
            updatedList.push(item)
          }
        })
        return updatedList
      },

      getPreferredData (type) {
        this.holdersData.forEach((item) => {
          if (item && item.share_terms_ids && item.share_terms_ids.length > 0) {
            item.filterdSecurities = item.securities.filter((term) => {
              if (term && term.type === type) {
                return term
              } else if (!type) {
                return term
              }
            })
          }
        })
        return this.holdersData.filter((item) => {
          if (item && item.filterdSecurities && item.filterdSecurities.length > 0) {
            return item
          }
        })
      },
      getWarrantData (type) {
        this.holdersData.forEach((item) => {
          if (item && item.warrant_terms_ids && item.warrant_terms_ids.length > 0) {
            item.filterdSecurities = item.securities.filter((term) => {
              if (term && term.type === type) {
                return term
              }
            })
          }
        })
        return this.holdersData.filter((item) => {
          if (item && item.filterdSecurities && item.filterdSecurities.length > 0) {
            return item
          }
        })
      },
      getOptionsData (type) {
        this.holdersData.forEach((item) => {
          if (item && item.option_share_terms_ids && item.option_share_terms_ids.length > 0) {
            item.filterdSecurities = item.securities.filter((term) => {
              if (term && term.type === type) {
                return term
              }
            })
          }
        })
        return this.holdersData.filter((item) => {
          if (item && item.filterdSecurities && item.filterdSecurities.length > 0) {
            return item
          }
        })
      },

      updateFilter (val) {
        let filterCommonDataArr = []
        let filterWarrantDataArr = []
        let filterPreferredDataArr = []
        let filterOptionDataArr = []
        const combinedFilterdata = []
        this.holdersData = JSON.parse(JSON.stringify(this.summaryDataForFilter))
        if (val.length > 0) {
          let bothSelected = false
          if (val.includes(this.CONSTS.SHARE_TERM_TYPES.PREFERRED) && val.includes(this.CONSTS.SHARE_TERM_TYPES.COMMON)) {
            bothSelected = true
            filterPreferredDataArr = this.getPreferredData(null)
          }
          if (val.includes(this.CONSTS.SHARE_TERM_TYPES.WARRANT)) {
            if (val.includes(this.CONSTS.SHARE_TERM_TYPES.COMMON)) {
              filterCommonDataArr = this.getPreferredData(this.CONSTS.SHARE_TERM_TYPES.COMMON)
            } else if (val.includes(this.CONSTS.SHARE_TERM_TYPES.PREFERRED)) {
              filterPreferredDataArr = this.getPreferredData(this.CONSTS.SHARE_TERM_TYPES.PREFERRED)
            } else if (val.includes(this.CONSTS.SHARE_TERM_TYPES.OPTION)) {
              filterOptionDataArr = this.getOptionsData(this.CONSTS.SHARE_TERM_TYPES.OPTION)
            } else if (val.includes(this.CONSTS.SHARE_TERM_TYPES.WARRANT)) {
              filterWarrantDataArr = this.getWarrantData(this.CONSTS.SHARE_TERM_TYPES.WARRANT)
            }
          }

          if (val.includes(this.CONSTS.SHARE_TERM_TYPES.OPTION)) {
            if (val.includes(this.CONSTS.SHARE_TERM_TYPES.COMMON)) {
              filterCommonDataArr = this.getPreferredData(this.CONSTS.SHARE_TERM_TYPES.COMMON)
            } else if (val.includes(this.CONSTS.SHARE_TERM_TYPES.PREFERRED)) {
              filterPreferredDataArr = this.getPreferredData(this.CONSTS.SHARE_TERM_TYPES.PREFERRED)
            } else if (val.includes(this.CONSTS.SHARE_TERM_TYPES.WARRANT)) {
              filterWarrantDataArr = this.getWarrantData(this.CONSTS.SHARE_TERM_TYPES.WARRANT)
            } else if (val.includes(this.CONSTS.SHARE_TERM_TYPES.OPTION)) {
              filterOptionDataArr = this.getOptionsData(this.CONSTS.SHARE_TERM_TYPES.OPTION)
            }
          } else {
            val.forEach(item => {
              if (item === this.CONSTS.SHARE_TERM_TYPES.COMMON && !bothSelected) {
                filterCommonDataArr = this.getPreferredData(item)
              } else if (item === this.CONSTS.SHARE_TERM_TYPES.PREFERRED && !bothSelected) {
                filterPreferredDataArr = this.getPreferredData(item)
              }
            })
          }
          this.holdersData = [...new Set(combinedFilterdata.concat(filterCommonDataArr, filterWarrantDataArr, filterPreferredDataArr, filterOptionDataArr))]
        }
      },
      updateSearchedHolder (val) {
        this.holdersData = JSON.parse(JSON.stringify(this.summaryDataForFilter))
        this.convertibleHoldersData = JSON.parse(JSON.stringify(this.otherHoldersData))
        this.holdersData = this.holdersData.filter((holder) => {
          if ((holder.holder.name).toLowerCase().indexOf((val).toLowerCase()) > -1) {
            return holder
          }
        })
        this.convertibleHoldersData = this.convertibleHoldersData?.filter((holder) => {
          if ((holder?.holder?.name).toLowerCase().indexOf((val).toLowerCase()) > -1) {
            return holder
          }
        })
      },
      navigateToHolderView (data) {
        const holderData = {
          first_name: data?.holder?.name,
          username: data?.holder?.email,
          id: data?.holder?.id,
        }
        this.$store.dispatch('auth/setHolder', { ...holderData, id: [holderData?.id] })
        this.$router.push({ name: 'Holder.CaptableSummary' })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .footer-bg {
    background: var(--v-accent-base);
  }
  .headerStyle {
    justify-content: space-between;
  }
  .badge-box-card {
    ::v-deep .badge-box {
      margin-right: 4px;
    }
  }
  .overflow-text {

  }
</style>
