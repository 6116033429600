<template>
  <captable-tabs />
</template>

<script>
  import CaptableTabs from './captableTab/CaptableTabs.vue'
  export default {
    name: 'Captable',
    components: {
      CaptableTabs,
    },
    props: {
    },
    data () {
      return {
        items: [],
      }
    },

    computed: {
    },

    created () {
    },

    methods: {
    },
  }
</script>
