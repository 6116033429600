<template>
  <div>
    <v-progress-linear
      v-if="loading"
      color="primary"
      indeterminate
    />
    <search-bar
      v-if="!isHolder"
      v-model="search"
      :label="label"
    >
      <template v-slot:content>
        <v-row class="no-gutters">
          <v-col
            cols="12"
            md="4"
            class="px-1"
          >
            <common-input-select
              v-model="filterText"
              label="Securities"
              placeholder="Select Securities"
              :items="CONSTS.SUMMARY_FILTER_SECURITIES_TYPES"
              item-text="value"
              item-value="id"
              multiple
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions>
        <captable-filters
          v-model="captableFilters"
          :is-summary-view="true"
          @input="updateAsofDate"
        />
      </template>
    </search-bar>
    <captable-filters
      v-else
      v-model="captableFilters"
      :is-summary-view="true"
      :is-holder="isHolder"
      @input="updateAsofDate"
    />
    <div v-if="dataAvailable">
      <div v-if="captableFilters.include_financingRounds">
        <div
          v-for="(shareItem, key) of filteredSummaryData"
          :key="key"
        >
          <div v-if="showCommonList(shareItem) || showPreferredList(shareItem)">
            <v-row v-if="shareItem && shareItem.shareList && shareItem.shareList.length > 0">
              <v-col
                cols="12"
                class="mx-2 trsText--text text-h4 font-weight-medium"
              >
                {{ shareItem.title }}
              </v-col>
              <v-col
                cols="12"
                class="pa-0"
              >
                <common-trs-data-table
                  :headers="summaryViewHeaders"
                  :items="shareItem.shareList"
                  :custom-filter="customFilter"
                  :search="search"
                  show-expand
                  class="trs-table-container-card"
                  hide-default-footer
                  :expanded.sync="shareItem.expanded"
                  disable-pagination
                  item-key="index"
                >
                  <template v-slot:[`item.name`]="{item}">
                    <span class="trsText--text font-weight-medium text-body-1">
                      {{ item.name || 'Others' }}
                    </span>
                  </template>
                  <template v-slot:[`item.number_outstanding`]="{item}">
                    <span class="trsText--text font-weight-medium text-body-1">
                      {{ item.total['number_outstanding'] }}
                    </span>
                  </template>
                  <template v-slot:[`item.ratio_outstanding`]="{item}">
                    <span class="trsText--text font-weight-medium text-body-1">
                      {{ item.total['ratio_outstanding'] }} %
                    </span>
                  </template>
                  <template v-slot:[`item.fully_diluted`]="{item}">
                    <span class="trsText--text font-weight-medium text-body-1">
                      {{ item.total['number_fully_diluted'] }}
                    </span>
                  </template>
                  <template v-slot:[`item.fully_diluted_ratio`]="{item}">
                    <span class="trsText--text font-weight-medium text-body-1">
                      {{ item.total['ratio_fully_diluted'] }} %
                    </span>
                  </template>
                  <template
                    v-slot:expanded-item="{ headers, item }"
                  >
                    <td
                      :colspan="headers.length"
                      class="inner-table"
                    >
                      <!-- Share Terms Section -->
                      <common-trs-data-table
                        v-if="(item.share_terms && item.share_terms.length > 0) && (commonFilterApplied || preferredFilterApplied)"
                        :headers="shareTermHeaders"
                        :items="item.share_terms"
                        :custom-filter="customFilter"
                        :search="search"
                        show-expand
                        hide-default-header
                        hide-default-footer
                        :expanded.sync="item.expanded"
                        disable-pagination
                      >
                        <template v-slot:item="{ expand, isExpanded, item }">
                          <tr>
                            <td
                              :colspan="headers.length"
                            >
                              <v-row class="trsText--text font-weight-medium text-body-1 text-right">
                                <v-col
                                  cols="4"
                                  class="text-left"
                                >
                                  <div class="d-flex ml-1">
                                    <div
                                      v-if="item.name != 'Total'"
                                      class="cursor-pointer pr-1"
                                      @click="expand(!isExpanded)"
                                    >
                                      <v-icon v-if="!isExpanded">
                                        mdi-chevron-down
                                      </v-icon>
                                      <v-icon v-else>
                                        mdi-chevron-up
                                      </v-icon>
                                    </div>
                                    <div
                                      v-for="(shareTerm, key) in shareTermList"
                                      :key="key"
                                    >
                                      <share-term-badge
                                        v-if="shareTerm.id === item.share_terms_id"
                                        :type="shareTerm.type"
                                        :show-single="true"
                                      />
                                    </div>
                                    <div
                                      class="ml-0"
                                    >
                                      <span>
                                        {{ item.share_terms_name }}
                                      </span>
                                      <div class="trsText--text font-weight-light text-body-2 text--lighten-1 pl-1">
                                        {{ item.shares.length }} {{ item.shares.length === 1 ?'Holder':'Holders' }}
                                      </div>
                                    </div>
                                  </div>
                                </v-col>

                                <v-col
                                  cols="2"
                                  class="pr-1"
                                >
                                  {{ $number(item.number_outstanding) }}
                                </v-col>
                                <v-col
                                  cols="2"
                                >
                                  {{ (item.ratio_outstanding*100).toFixed(2) }} %
                                </v-col>
                                <v-col
                                  cols="2"
                                  class="pr-2"
                                >
                                  {{ $number(item.number_fully_diluted) }}
                                </v-col>
                                <v-col
                                  cols="2"
                                >
                                  {{ (item.ratio_fully_diluted*100).toFixed(2) }} %
                                </v-col>
                              </v-row>
                            </td>
                          </tr>
                        </template>

                        <template v-slot:expanded-item="{item}">
                          <tr
                            :class="isHolder ? 'cursor-pointer' : ''"
                            @click="navigateToDetailsPage(item.share_terms_id, item.share_terms_type)"
                          >
                            <td
                              :colspan="headers.length"
                            >
                              <v-row
                                v-for="(obj,key) in item.shares"
                                :key="key"
                                class="trsText--text text-body-2 text-right"
                              >
                                <v-col
                                  cols="4"
                                  class="text-left"
                                >
                                  <span>{{ obj.holder.name }}</span>
                                </v-col>
                                <v-col
                                  cols="2"
                                  class="pr-1"
                                >
                                  {{ $number(obj.number_outstanding) }}
                                </v-col>
                                <v-col
                                  cols="2"
                                >
                                  {{ (obj.ratio_outstanding*100).toFixed(2) }}%
                                </v-col>
                                <v-col
                                  cols="2"
                                  class="pr-2"
                                >
                                  {{ $number(obj.number_fully_diluted) }}
                                </v-col>
                                <v-col
                                  cols="2"
                                  class="pr-4"
                                >
                                  {{ (obj.ratio_fully_diluted*100).toFixed(2) }}%
                                </v-col>
                              </v-row>
                            </td>
                          </tr>
                        </template>
                      </common-trs-data-table>
                      <!-- Warrant Terms Section -->
                      <common-trs-data-table
                        v-if="item.warrant_terms && item.warrant_terms.length > 0"
                        :headers="shareTermHeaders"
                        :items="item.warrant_terms"
                        :search="search"
                        :custom-filter="customFilter"
                        show-expand
                        hide-default-header
                        hide-default-footer
                        :expanded.sync="item.expanded"
                        disable-pagination
                      >
                        <template v-slot:item="{ expand, isExpanded, item }">
                          <tr>
                            <td
                              :colspan="headers.length"
                            >
                              <v-row class="trsText--text font-weight-medium text-body-1 text-right">
                                <v-col
                                  cols="4"
                                  class="text-left"
                                >
                                  <div class="d-flex ml-1">
                                    <div
                                      v-if="item.name != 'Total'"
                                      class="cursor-pointer pr-1"
                                      @click="expand(!isExpanded)"
                                    >
                                      <v-icon v-if="!isExpanded">
                                        mdi-chevron-down
                                      </v-icon>
                                      <v-icon v-else>
                                        mdi-chevron-up
                                      </v-icon>
                                    </div>
                                    <div
                                      v-for="(term, key) in warrantTermList"
                                      :key="key"
                                    >
                                      <share-term-badge
                                        v-if="term.id === item.warrant_terms_id"
                                        :type="CONSTS.SHARE_TERM_TYPES.WARRANT"
                                        :show-single="true"
                                      />
                                    </div>
                                    <div
                                      class="ml-0"
                                    >
                                      <span>
                                        {{ item.warrant_terms_name }}
                                      </span>
                                      <div class="trsText--text font-weight-light text-body-2 text--lighten-1 pl-1">
                                        {{ item.warrants.length }} {{ item.warrants.length === 1 ?'Holder':'Holders' }}
                                      </div>
                                    </div>
                                  </div>
                                </v-col>

                                <v-col
                                  cols="2"
                                >
                                  NA
                                </v-col>
                                <v-col cols="2">
                                  NA
                                </v-col>
                                <v-col
                                  cols="2"
                                >
                                  {{ $number(item.number_fully_diluted) }}
                                </v-col>
                                <v-col
                                  cols="2"
                                >
                                  {{ (item.ratio_fully_diluted*100).toFixed(2) }} %
                                </v-col>
                              </v-row>
                            </td>
                          </tr>
                        </template>

                        <template v-slot:expanded-item="{item}">
                          <tr
                            :class="isHolder ? 'cursor-pointer' : ''"
                            @click="navigateToDetailsPage(item.warrant_terms_id, CONSTS.SHARE_TERM_TYPES.WARRANT)"
                          >
                            <td
                              :colspan="headers.length"
                            >
                              <v-row
                                v-for="(obj,key) in item.warrants"
                                :key="key"
                                class="trsText--text text-body-2 text-right"
                              >
                                <v-col
                                  cols="4"
                                  class="text-left"
                                >
                                  <span>{{ obj.holder.name }}</span>
                                </v-col>
                                <v-col
                                  cols="2"
                                >
                                  NA
                                </v-col>
                                <v-col cols="2">
                                  NA
                                </v-col>
                                <v-col
                                  cols="2"
                                >
                                  {{ $number(obj.number_fully_diluted) }}
                                </v-col>
                                <v-col
                                  cols="2"
                                >
                                  {{ (obj.ratio_fully_diluted*100).toFixed(2) }} %
                                </v-col>
                              </v-row>
                            </td>
                          </tr>
                        </template>
                      </common-trs-data-table>
                      <!-- Others -->
                      <common-trs-data-table
                        v-if="!item.name"
                        :headers="shareTermHeaders"
                        :items="item"
                        :search="search"
                        :custom-filter="customFilter"
                        show-expand
                        hide-default-header
                        hide-default-footer
                        :expanded.sync="item.expanded"
                        disable-pagination
                      >
                        <template v-slot:item="{ expand, isExpanded, item }">
                          <tr>
                            <td
                              :colspan="headers.length"
                            >
                              <v-row class="trsText--text font-weight-medium text-body-1 text-right">
                                <v-col
                                  cols="4"
                                  class="text-left"
                                >
                                  <div class="d-flex ml-1">
                                    <div
                                      v-if="item.name != 'Total'"
                                      class="cursor-pointer pr-1"
                                      @click="expand(!isExpanded)"
                                    >
                                      <v-icon v-if="!isExpanded">
                                        mdi-chevron-down
                                      </v-icon>
                                      <v-icon v-else>
                                        mdi-chevron-up
                                      </v-icon>
                                    </div>
                                    <div
                                      v-for="(shareTerm, key) in shareTermList"
                                      :key="key"
                                    >
                                      <share-term-badge
                                        v-if="shareTerm.id === item.share_terms_id"
                                        :type="shareTerm.type"
                                        :show-single="true"
                                      />
                                    </div>
                                    <div
                                      class="ml-0"
                                    >
                                      <span>
                                        {{ item.share_terms_name }}
                                      </span>
                                      <div class="trsText--text font-weight-light text-body-2 text--lighten-1 pl-1">
                                        {{ item.shares.length }} {{ item.shares.length === 1 ?'Holder':'Holders' }}
                                      </div>
                                    </div>
                                  </div>
                                </v-col>

                                <v-col
                                  cols="2"
                                  class="pr-0"
                                >
                                  {{ $number(item.number_outstanding) }}
                                </v-col>
                                <v-col cols="2">
                                  {{ (item.ratio_outstanding*100).toFixed(2) }}%
                                </v-col>
                                <v-col
                                  cols="2"
                                >
                                  {{ $number(item.number_fully_diluted) }}
                                </v-col>
                                <v-col
                                  cols="2"
                                >
                                  {{ (item.ratio_fully_diluted*100).toFixed(2) }}%
                                </v-col>
                              </v-row>
                            </td>
                          </tr>
                        </template>

                        <template v-slot:expanded-item="{item}">
                          <tr>
                            <td
                              :colspan="headers.length"
                            >
                              <v-row
                                v-for="(obj,key) in item.shares"
                                :key="key"
                                class="trsText--text text-body-2 text-right"
                              >
                                <v-col
                                  cols="4"
                                  class="text-left"
                                >
                                  <span>{{ obj.holder.name }}</span>
                                </v-col>
                                <v-col
                                  cols="2"
                                  class="pr-0"
                                >
                                  {{ $number(obj.number_outstanding) }}
                                </v-col>
                                <v-col cols="2">
                                  {{ (obj.ratio_outstanding*100).toFixed(2) }}%
                                </v-col>
                                <v-col
                                  cols="2"
                                >
                                  {{ $number(obj.number_fully_diluted) }}
                                </v-col>
                                <v-col
                                  cols="2"
                                >
                                  {{ (obj.ratio_fully_diluted*100).toFixed(2) }}%
                                </v-col>
                              </v-row>
                            </td>
                          </tr>
                        </template>
                      </common-trs-data-table>
                    </td>
                  </template>
                  <!-- Total Row -->
                  <template v-slot:[`body.append`]="{ headers, items }">
                    <tr>
                      <td
                        :colspan="headers.length"
                        class="px-2 footer-bg"
                      >
                        <v-row
                          v-if="equitySummaryData"
                          class="trsText--text font-weight-bold text-body-1 text-right no-gutters"
                        >
                          <v-col
                            cols="4"
                            class="text-left pl-12"
                          >
                            Total
                          </v-col>
                          <v-col
                            cols="2"
                          >
                            <span class="mr-m2">
                              {{ $number(parseFloat(calculateSum(items, 'number_outstanding'))) }}
                            </span>
                          </v-col>
                          <v-col
                            cols="2"
                            class="pr-2"
                          >
                            <span>
                              {{ (calculateSum(items, 'ratio_outstanding') * 100).toFixed(2) }} %
                            </span>
                          </v-col>
                          <v-col
                            cols="2"
                          >
                            <span class="mr-m2">
                              {{ $number(parseFloat(calculateSum(items, 'number_fully_diluted'))) }}
                            </span>
                          </v-col>
                          <v-col
                            cols="2"
                            class="pr-2"
                          >
                            <span>
                              {{ (calculateSum(items, 'ratio_fully_diluted') * 100 ).toFixed(2) }} %
                            </span>
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </template>
                </common-trs-data-table>
              </v-col>
            </v-row>
          </div>
        </div>
        <!-- Other Securities Section -->
        <v-row v-if="otherSecurities && ((otherSecurities.convertibleList && otherSecurities.convertibleList.length > 0) || (otherSecurities.optionList && otherSecurities.optionList.length > 0 && optionFilterApplied))">
          <v-col
            cols="12"
            class="ma-2 trsText--text text-h4 font-weight-medium"
          >
            Other Securities
          </v-col>
          <!-- Convertible Section -->
          <v-col
            v-if="otherSecurities && otherSecurities.convertibleList && otherSecurities.convertibleList.length > 0"
            cols="12"
            class="mx-2 trsText--text text-body-1 font-weight-medium"
          >
            Convertible Securities
          </v-col>
          <v-col cols="12">
            <common-trs-data-table
              v-if="otherSecurities && otherSecurities.convertibleList && otherSecurities.convertibleList.length > 0"
              :headers="otherSecurityTableHeader"
              :items="otherSecurities.convertibleList"
              :custom-filter="customFilter"
              :search="search"
              show-expand
              class="trs-table-container-card"
              hide-default-footer
              :expanded.sync="otherSecuritiesExpanded"
              disable-pagination
            >
              <template v-slot:[`item.name`]="{item}">
                <span class="trsText--text font-weight-medium text-body-1">
                  {{ item.name }}
                </span>
              </template>
              <template v-slot:[`item.all_principal`]="{item}">
                <span class="trsText--text font-weight-medium text-body-1">
                  {{ $currencyWithPrecision(getAmountInThousands(getCalculatedSum(item.convertible_terms, 'principal'))) }}
                </span>
              </template>
              <template v-slot:[`item.all_interest`]="{item}">
                <span class="trsText--text font-weight-medium text-body-1">
                  {{ $currencyWithPrecision(getAmountInThousands(getCalculatedSum(item.convertible_terms, 'interest'))) }}
                </span>
              </template>
              <template v-slot:[`item.all_total`]="{item}">
                <span class="trsText--text font-weight-medium text-body-1">
                  {{ $currencyWithPrecision(getAmountInThousands(getCalculatedSum(item.convertible_terms, 'total'))) }}
                </span>
              </template>
              <template
                v-slot:expanded-item="{ headers, item }"
              >
                <td
                  :colspan="headers.length"
                  class="inner-table"
                >
                  <common-trs-data-table
                    :headers="otherSecuritySubTableHeader"
                    :items="item.convertible_terms"
                    :custom-filter="customFilter"
                    show-expand
                    hide-default-header
                    hide-default-footer
                    :expanded.sync="item.expanded"
                    disable-pagination
                  >
                    <template v-slot:item="{ expand, isExpanded, item }">
                      <tr>
                        <td
                          :colspan="headers.length"
                        >
                          <v-row class="trsText--text font-weight-medium text-body-1 text-right">
                            <v-col
                              cols="4"
                              class="text-left"
                            >
                              <div class="d-flex ml-1">
                                <div
                                  v-if="item.name != 'Total'"
                                  class="cursor-pointer pr-1"
                                  @click="expand(!isExpanded)"
                                >
                                  <v-icon v-if="!isExpanded">
                                    mdi-chevron-down
                                  </v-icon>
                                  <v-icon v-else>
                                    mdi-chevron-up
                                  </v-icon>
                                </div>
                                <div>
                                  <share-term-badge
                                    :type="item.type"
                                    :show-single="true"
                                  />
                                </div>
                                <div
                                  class="ml-0"
                                >
                                  <span>
                                    {{ item.name }}
                                  </span>
                                  <div class="trsText--text font-weight-light text-body-2 text--lighten-1">
                                    {{ item.convertibles.length }} {{ item.convertibles.length === 1 ?'Holder':'Holders' }}
                                  </div>
                                </div>
                              </div>
                            </v-col>

                            <v-col
                              cols="2"
                            >
                              {{ item.matures_on }}
                            </v-col>
                            <v-col
                              cols="2"
                              class="pr-6"
                            >
                              {{ $currencyWithPrecision(getAmountInThousands(item.principal)) }}
                            </v-col>
                            <v-col
                              cols="2"
                              class="pr-6"
                            >
                              {{ $currencyWithPrecision(getAmountInThousands(item.interest)) }}
                            </v-col>
                            <v-col
                              cols="2"
                            >
                              {{ $currencyWithPrecision(getAmountInThousands(item.total)) }}
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                    </template>

                    <template v-slot:expanded-item="{item}">
                      <tr
                        :class="isHolder ? 'cursor-pointer' : ''"
                        @click="navigateToDetailsPage(item.id, CONSTS.SHARE_TERM_TYPES.CONVERTIBLE)"
                      >
                        <td
                          :colspan="headers.length"
                        >
                          <v-row
                            v-for="(obj,key) in item.convertibles"
                            :key="key"
                            class="trsText--text text-body-2 text-right"
                          >
                            <v-col
                              offset="1"
                              cols="3"
                              class="text-left"
                            >
                              <span>{{ obj.holder.name }}</span>
                            </v-col>
                            <v-col
                              cols="2"
                              class="text-center"
                            >
                              {{ $date(obj.matures_on) }}
                            </v-col>
                            <v-col
                              cols="2"
                              class="pr-6"
                            >
                              {{ $currencyWithPrecision(getAmountInThousands(obj.principal)) }}
                            </v-col>
                            <v-col
                              cols="2"
                              class="pr-6"
                            >
                              {{ $currencyWithPrecision(getAmountInThousands(obj.interest)) }}
                            </v-col>
                            <v-col
                              cols="2"
                            >
                              {{ $currencyWithPrecision(getAmountInThousands(obj.total)) }}
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                    </template>
                  </common-trs-data-table>
                </td>
              </template>
              <!-- Total Row -->
              <template v-slot:[`body.append`]="{headers}">
                <tr>
                  <td
                    :colspan="headers.length"
                    class="px-2 footer-bg"
                  >
                    <v-row
                      v-if="equitySummaryData"
                      class="trsText--text font-weight-bold text-body-1 text-right no-gutters"
                    >
                      <v-col
                        cols="4"
                        class="text-left pl-12"
                      >
                        Total
                      </v-col>
                      <v-col
                        cols="2"
                      />
                      <v-col
                        cols="2"
                        class="pr-2"
                      >
                        <span>
                          {{ captableData.convertible_summary ? $currencyWithPrecision(getAmountInThousands(captableData.convertible_summary.principal)) : 0 }}
                        </span>
                      </v-col>
                      <v-col
                        cols="2"
                        class="pr-2"
                      >
                        <span>
                          {{ captableData.convertible_summary ? $currencyWithPrecision(getAmountInThousands(captableData.convertible_summary.interest)) : 0 }}
                        </span>
                      </v-col>
                      <v-col
                        cols="2"
                        class="pr-2"
                      >
                        <span>
                          {{ captableData.convertible_summary ? $currencyWithPrecision(getAmountInThousands(captableData.convertible_summary.total)) : 0 }}
                        </span>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </template>
            </common-trs-data-table>
          </v-col>
          <!-- EIP Section -->
          <v-col
            v-if="otherSecurities && otherSecurities.optionList && otherSecurities.optionList.length > 0 && optionFilterApplied"
            cols="12"
            class="mx-2 trsText--text text-body-1 font-weight-medium"
          >
            Equity Incentive Plans
          </v-col>
          <v-col cols="12">
            <common-trs-data-table
              v-if="otherSecurities && otherSecurities.optionList && otherSecurities.optionList.length > 0 && optionFilterApplied"
              :headers="summaryViewHeaders"
              :items="otherSecurities.optionList"
              :custom-filter="customFilter"
              :search="search"
              show-expand
              class="trs-table-container-card"
              :loading="loading"
              hide-default-footer
              :expanded.sync="eipTableExpanded"
              disable-pagination
            >
              <template v-slot:[`item.name`]="{item}">
                <span class="trsText--text font-weight-medium text-body-1">
                  {{ item.name }}
                </span>
              </template>
              <template v-slot:[`item.number_outstanding`]="{item}">
                <span class="trsText--text font-weight-medium text-body-1">
                  {{ $number(parseFloat(calculateEipSum([item], 'number_outstanding'))) }}
                </span>
              </template>
              <template v-slot:[`item.ratio_outstanding`]="{item}">
                <span class="trsText--text font-weight-medium text-body-1">
                  {{ (calculateEipSum([item], 'ratio_outstanding') * 100).toFixed(2) }} %
                </span>
              </template>
              <template v-slot:[`item.fully_diluted`]="{item}">
                <span class="trsText--text font-weight-medium text-body-1">
                  {{ $number(parseFloat(calculateEipSum([item], 'number_fully_diluted'))) }}
                </span>
              </template>
              <template v-slot:[`item.fully_diluted_ratio`]="{item}">
                <span class="trsText--text font-weight-medium text-body-1">
                  {{ (calculateEipSum([item], 'ratio_fully_diluted') * 100 ).toFixed(2) }} %
                </span>
              </template>
              <template
                v-slot:expanded-item="{ headers, item }"
              >
                <td
                  :colspan="headers.length"
                  class="inner-table"
                >
                  <!-- Share Terms Section -->
                  <common-trs-data-table
                    v-if="(item.share_terms && item.share_terms.length > 0) && (commonFilterApplied || preferredFilterApplied)"
                    :headers="shareTermHeaders"
                    :items="item.share_terms"
                    :custom-filter="customFilter"
                    :search="search"
                    show-expand
                    hide-default-header
                    hide-default-footer
                    :expanded.sync="item.expand"
                    disable-pagination
                  >
                    <template v-slot:item="{ expand, isExpanded, item }">
                      <tr>
                        <td
                          :colspan="headers.length"
                        >
                          <v-row class="trsText--text font-weight-medium text-body-1 text-right">
                            <v-col
                              cols="4"
                              class="text-left"
                            >
                              <div class="d-flex ml-1">
                                <div
                                  class="cursor-pointer pr-1"
                                  @click="expand(!isExpanded)"
                                >
                                  <v-icon v-if="!isExpanded">
                                    mdi-chevron-down
                                  </v-icon>
                                  <v-icon v-else>
                                    mdi-chevron-up
                                  </v-icon>
                                </div>
                                <div
                                  v-for="(shareTerm, key) in shareTermList"
                                  :key="key"
                                >
                                  <share-term-badge
                                    v-if="shareTerm.id === item.share_terms_id"
                                    :type="shareTerm.type"
                                    :show-single="true"
                                  />
                                </div>
                                <div
                                  class="ml-0"
                                >
                                  <span>
                                    {{ item.share_terms_name }}
                                  </span>
                                  <div class="trsText--text font-weight-light text-body-2 text--lighten-1">
                                    {{ item.shares.length }} {{ item.shares.length === 1 ?'Holder':'Holders' }}
                                  </div>
                                </div>
                              </div>
                            </v-col>

                            <v-col
                              cols="2"
                              class="pr-0"
                            >
                              {{ $number(item.number_outstanding) }}
                            </v-col>
                            <v-col cols="2">
                              {{ (item.ratio_outstanding*100).toFixed(2) }} %
                            </v-col>
                            <v-col
                              cols="2"
                            >
                              {{ $number(item.number_fully_diluted) }}
                            </v-col>
                            <v-col
                              cols="2"
                            >
                              {{ (item.ratio_fully_diluted*100).toFixed(2) }} %
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                    </template>

                    <template v-slot:expanded-item="{item}">
                      <tr
                        :class="isHolder ? 'cursor-pointer' : ''"
                        @click="navigateToDetailsPage(item.share_terms_id, item.share_terms_type)"
                      >
                        <td
                          :colspan="headers.length"
                        >
                          <v-row
                            v-for="(obj,key) in item.shares"
                            :key="key"
                            class="trsText--text text-body-2 text-right"
                          >
                            <v-col
                              offset="1"
                              cols="3"
                              class="text-left"
                            >
                              <span>{{ obj.holder.name }}</span>
                            </v-col>
                            <v-col
                              cols="2"
                              class="pr-0"
                            >
                              {{ $number(obj.number_outstanding) }}
                            </v-col>
                            <v-col cols="2">
                              {{ (obj.ratio_outstanding*100).toFixed(2) }} %
                            </v-col>
                            <v-col
                              cols="2"
                            >
                              {{ $number(obj.number_fully_diluted) }}
                            </v-col>
                            <v-col
                              cols="2"
                            >
                              {{ (obj.ratio_fully_diluted*100).toFixed(2) }} %
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                    </template>
                  </common-trs-data-table>
                  <!-- Options Section -->
                  <common-trs-data-table
                    v-if="item.optionList && item.optionList.length > 0"
                    :headers="shareTermHeaders"
                    :items="item.optionList"
                    :search="search"
                    :custom-filter="customFilter"
                    show-expand
                    hide-default-header
                    hide-default-footer
                    :expanded.sync="item.expanded"
                    disable-pagination
                  >
                    <template v-slot:item="{ expand, isExpanded, item }">
                      <tr>
                        <td
                          :colspan="headers.length"
                        >
                          <v-row class="trsText--text font-weight-medium text-body-1 text-right">
                            <v-col
                              cols="4"
                              class="text-left"
                            >
                              <div class="d-flex ml-1">
                                <div
                                  v-if="item.name != 'Total'"
                                  class="cursor-pointer"
                                  @click="expand(!isExpanded)"
                                >
                                  <v-icon v-if="!isExpanded">
                                    mdi-chevron-down
                                  </v-icon>
                                  <v-icon v-else>
                                    mdi-chevron-up
                                  </v-icon>
                                </div>
                                <div>
                                  <share-term-badge
                                    :type="CONSTS.OPTIONS_TYPE.ISO"
                                    :show-single="true"
                                  />
                                </div>
                                <div
                                  class="ml-0"
                                >
                                  <span>
                                    Options
                                  </span>
                                  <div class="trsText--text font-weight-light text-body-2 text--lighten-1 pl-1">
                                    {{ item.options.length }} {{ item.options.length === 1 ?'Holder':'Holders' }}
                                  </div>
                                </div>
                              </div>
                            </v-col>

                            <v-col
                              cols="2"
                            >
                              NA
                            </v-col>
                            <v-col cols="2">
                              NA
                            </v-col>
                            <v-col
                              cols="2"
                            >
                              {{ $number(item.number_fully_diluted) }}
                            </v-col>
                            <v-col
                              cols="2"
                            >
                              {{ (item.ratio_fully_diluted*100).toFixed(2) }} %
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                    </template>

                    <template v-slot:expanded-item="{item}">
                      <tr>
                        <td
                          :colspan="headers.length"
                        >
                          <v-row
                            v-for="(obj,key) in item.options"
                            :key="key"
                            class="trsText--text text-body-2 text-right"
                          >
                            <v-col
                              offset="1"
                              cols="3"
                              class="text-left"
                            >
                              <span>{{ obj.holder.name }}</span>
                            </v-col>
                            <v-col
                              cols="2"
                            >
                              NA
                            </v-col>
                            <v-col cols="2">
                              NA
                            </v-col>
                            <v-col
                              cols="2"
                            >
                              {{ $number(obj.number_fully_diluted) }}
                            </v-col>
                            <v-col
                              cols="2"
                            >
                              {{ (obj.ratio_fully_diluted*100).toFixed(2) }} %
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                      <tr>
                        <td
                          :colspan="headers.length"
                        >
                          <v-row
                            class="trsText--text text-body-2 text-right font-weight-medium"
                          >
                            <v-col
                              offset="1"
                              cols="3"
                              class="text-left"
                            >
                              Equity available for issuance
                            </v-col>
                            <v-col
                              cols="2"
                            >
                              NA
                            </v-col>
                            <v-col cols="2">
                              NA
                            </v-col>
                            <v-col
                              cols="2"
                              class="pr-0"
                            >
                              {{ $number(item.equity_available_for_issuance.number_fully_diluted) }}
                            </v-col>
                            <v-col
                              cols="2"
                              class="pr-0"
                            >
                              {{ (item.equity_available_for_issuance.ratio_fully_diluted*100).toFixed(2) }}%
                            </v-col>
                          </v-row>
                        </td>
                      </tr>
                    </template>
                  </common-trs-data-table>
                </td>
              </template>
              <!-- Total Row -->
              <template v-slot:[`body.append`]="{ headers, items }">
                <tr>
                  <td
                    :colspan="headers.length"
                    class="px-2 footer-bg"
                  >
                    <v-row
                      v-if="equitySummaryData"
                      class="trsText--text font-weight-bold text-body-1 text-right no-gutters"
                    >
                      <v-col
                        cols="4"
                        class="text-left pl-12"
                      >
                        Total
                      </v-col>
                      <v-col
                        cols="2"
                      >
                        <span class="mr-m2">
                          {{ $number(parseFloat(calculateEipSum(items, 'number_outstanding'))) }}
                        </span>
                      </v-col>
                      <v-col
                        cols="2"
                        class="pr-2"
                      >
                        <span>
                          {{ (calculateEipSum(items, 'ratio_outstanding') * 100).toFixed(2) }} %
                        </span>
                      </v-col>
                      <v-col
                        cols="2"
                      >
                        <span class="mr-m2">
                          {{ $number(parseFloat(calculateEipSum(items, 'number_fully_diluted'))) }}
                        </span>
                      </v-col>
                      <v-col
                        cols="2"
                        class="pr-2"
                      >
                        <span>
                          {{ (calculateEipSum(items, 'ratio_fully_diluted') * 100 ).toFixed(2) }} %
                        </span>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </template>
            </common-trs-data-table>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <captable-summary-non-financing
          :share-term-headers="shareTermHeaders"
          :convertible-share-header="otherSecurityTableHeader"
          :convertible-term-header="otherSecuritySubTableHeader"
          :summary-view-headers="summaryViewHeaders"
          :summary-data="summaryData"
          :custom-filter="customFilter"
          :search="search"
          :common-filter-applied="commonFilterApplied"
          :preferred-filter-applied="preferredFilterApplied"
          :option-filter-applied="optionFilterApplied"
          :convertible-filter-applied="convertibleFilterApplied"
          :share-term-list="shareTermList"
          :warrant-term-list="warrantTermList"
          :convertible-term-list="convertibleTermList"
          :is-holder="isHolder"
          :calculate-sum="getCalculatedSum"
          :calculate-eip-sum="calculateEipSum"
        />
      </div>
    </div>
    <div
      v-else
      class="text-center ma-10"
    >
      No data available
    </div>
  </div>
</template>

<script>
  import * as captableService from '@/components/common/captable/services/captable'
  import ShareTermBadge from '@/components/common/captable/components/ShareTermBadge'
  import CaptableFilters from './CaptableFilters'
  import moment from 'moment'
  import SearchBar from '@/components/common/captable/components/Search'
  import CaptableSummaryNonFinancing from './CaptableSummaryNonFinancing.vue'

  export default {
    name: 'CaptableSummaryView',

    components: { ShareTermBadge, CaptableFilters, SearchBar, CaptableSummaryNonFinancing },

    data: () => ({
      equitySummaryData: null,
      financingRoundExpanded: [],
      summaryDataForFilter: [],
      otherSecuritiesExpanded: [],
      eipTableExpanded: [],
      shareTermList: [],
      singleExpand: false,
      isExpanded: false,
      filterExpanded: false,
      filterText: [],
      summaryViewHeaders: [
        { text: 'Securities', value: 'name', sortable: true, width: '30%' },
        { text: 'Outstanding', value: 'number_outstanding', sortable: false, align: 'right' },
        { text: 'Ownership', value: 'ratio_outstanding', sortable: false, align: 'right' },
        { text: 'Fully Diluted', value: 'fully_diluted', sortable: false, align: 'right' },
        { text: 'Ownership', value: 'fully_diluted_ratio', sortable: false, align: 'right' },
      ],
      shareTermHeaders: [
        { value: 'share_terms_name', sortable: true },
        { value: 'number_outstanding', sortable: false, align: 'right' },
        { value: 'ratio_outstanding', sortable: false, align: 'right' },
        { value: 'number_fully_diluted', sortable: false, align: 'right' },
        { value: 'ratio_fully_diluted', sortable: false, align: 'right' },
      ],
      otherSecurityTableHeader: [
        { text: 'Securities', value: 'name', sortable: false, width: '25%' },
        { text: 'Maturity date', value: 'maturity', sortable: false, align: 'right' },
        { text: 'Principal', text2: '(in Thousands)', value: 'all_principal', sortable: false, align: 'right' },
        { text: 'Interest', text2: '(in Thousands)', value: 'all_interest', sortable: false, align: 'right' },
        { text: 'Total', text2: '(in Thousands)', value: 'all_total', sortable: false, align: 'right' },
      ],
      otherSecuritySubTableHeader: [
        { value: 'name', sortable: true, width: '25%' },
        { value: 'matures_on', sortable: true, align: 'right' },
        { value: 'principal', sortable: true, align: 'right' },
        { value: 'interest', sortable: true, align: 'right' },
        { value: 'total', sortable: true, align: 'right' },
      ],
      loading: false,
      totalCount: null,
      summaryData: {},
      captableFilters: { include_financingRounds: false },
      date: '',
      label: 'Search Holders',
      search: '',
      includeDraft: false,
      warrantTermList: [],
      captableData: null,
      filteredSummaryData: [],
      convertibleTermList: [],
    }),
    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      isHolder () {
        return this.$store.getters['auth/roles'].includes(this.CONSTS.USER_ROLES.HOLDER)
      },
      convertibleSecurities () {
        let convertibleList = []
        let debtTerms = []
        let safeTerms = []
        if (this.isHolder) {
          convertibleList = this.getUpdatedOtherSecuritiesForHolder()
        } else {
          convertibleList = this.captableData && this.captableData.convertible_summary && this.captableData.convertible_summary.financing_rounds
        }
        if (convertibleList && convertibleList.length > 0) {
          convertibleList.forEach((item) => {
            item.convertible_terms.forEach((term) => {
              const linkedTerm1Details = this.convertibleTermList.find(w => w.id === term.id)
              term.type = linkedTerm1Details?.convertible_type
            })
            debtTerms = item?.convertible_terms.filter((term) => term.type === this.CONSTS.CONVERTIBLE_TERM_TYPES.DEBT)
            safeTerms = item?.convertible_terms.filter((term) => term.type === this.CONSTS.CONVERTIBLE_TERM_TYPES.SAFE)

            debtTerms = this.getSortedListByBoardDate(debtTerms, 'convertible')
            safeTerms = this.getSortedListByBoardDate(safeTerms, 'convertible')

            item.convertible_terms = [...new Set(safeTerms.concat(debtTerms))]
          })
        }
        return convertibleList
      },
      eipSecurities () {
        let eips = []
        if (this.equitySummaryData && this.equitySummaryData.equity_incentive_plans) {
          eips = this.equitySummaryData.equity_incentive_plans.equity_incentive_plans.filter((item) => {
            return item.number_fully_diluted > 0
          })
          eips.forEach((eip) => {
            let commonSecurities = eip.share_terms.filter(e => e.share_terms_type === this.CONSTS.SHARE_TERM_TYPES.COMMON)
            let preferredSecurities = eip.share_terms.filter(e => e.share_terms_type === this.CONSTS.SHARE_TERM_TYPES.PREFERRED)
            commonSecurities = this.getSortedListByBoardDate(commonSecurities)
            preferredSecurities = this.getSortedListByBoardDate(preferredSecurities)
            eip.share_terms = [...new Set(commonSecurities.concat(preferredSecurities))]
          })
        }
        eips = eips.map(e => ({ ...e, optionList: [e] }))

        if (this.isHolder) {
          eips = this.getUpdatedHoldersData(eips)
        }

        eips.forEach(item => {
          const shareTermNumOutstanding = parseFloat(this.getCalculatedSum(item.share_terms, 'number_outstanding'))
          const shareTermRatioOutstanding = (this.getCalculatedSum(item.share_terms, 'ratio_outstanding') * 100).toFixed(2)
          const shareTermNumFullyDiluted = parseFloat(this.getCalculatedSum(item.share_terms, 'number_fully_diluted'))
          const shareTermRatioFullyDiluted = this.getCalculatedSum(item.share_terms, 'ratio_fully_diluted')
          let optionNumFullyDiluted = 0
          let optionRatioFullyDiluted = 0
          if (eips.optionList) {
            eips.optionList.forEach(optItem => {
              optionNumFullyDiluted = parseFloat(this.getCalculatedSum(optItem.options, 'number_fully_diluted'))
              optionRatioFullyDiluted = this.getCalculatedSum(optItem.options, 'ratio_fully_diluted')
            })
          }
          item.total = {
            number_outstanding: this.$number(shareTermNumOutstanding),
            ratio_outstanding: shareTermRatioOutstanding,
            number_fully_diluted: this.$number(shareTermNumFullyDiluted + optionNumFullyDiluted),
            ratio_fully_diluted: ((shareTermRatioFullyDiluted + optionRatioFullyDiluted) * 100).toFixed(2),
          }
        })
        return eips
      },
      otherSecurities () {
        return {
          convertibleList: this.convertibleSecurities,
          optionList: this.eipSecurities,
        }
      },
      commonFilterApplied () {
        return this.filterText.length > 0 ? this.filterText.includes(this.CONSTS.SHARE_TERM_TYPES.COMMON) : true
      },
      optionFilterApplied () {
        return this.filterText.length > 0 ? this.filterText.includes(this.CONSTS.SHARE_TERM_TYPES.OPTION) : true
      },
      preferredFilterApplied () {
        return this.filterText.length > 0 ? this.filterText.includes(this.CONSTS.SHARE_TERM_TYPES.PREFERRED) : true
      },
      convertibleFilterApplied () {
        return this.filterText.length > 0 ? this.filterText.includes(this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE) : true
      },
      dataAvailable () {
        let isData = true
        if ((!this.convertibleSecurities || this.convertibleSecurities.length === 0) && (!this.eipSecurities || this.eipSecurities.length === 0)) {
          isData = false
        }
        for (const item of this.filteredSummaryData) {
          if (item.shareList && item.shareList.length > 0) {
            isData = true
            break
          }
        }
        return isData
      },
    },

    watch: {
      captableFilters: {
        handler (val) {
          if (val) {
            this.date = val.date
            this.includeDraft = val.include_draft
            this.summaryData = {}
            this.loadSummaryViewData()
          }
        },
        immediate: true,
        deep: true,
      },
    },
    mounted () {
      this.loadSummaryViewData()
      this.getShareTermDetailsFromAPI()
      this.getWarrantTerms()
      this.getConvertibleTerms()
    },

    methods: {
      showCommonList (shareItem) {
        return (this.commonFilterApplied && shareItem?.title?.toLowerCase().indexOf(this.CONSTS.SHARE_TERM_TYPES.COMMON) > -1)
      },
      showPreferredList (shareItem) {
        return (this.preferredFilterApplied && shareItem?.title?.toLowerCase().indexOf(this.CONSTS.SHARE_TERM_TYPES.PREFERRED) > -1)
      },
      showOthersConvertible (shareItem) {
        return (this.convertibleFilterApplied && shareItem?.subTitle?.toLowerCase().indexOf(this.CONSTS.SHARE_TERM_TYPES.CONVERTIBLE) > -1)
      },
      showOthersEip (shareItem) {
        return (this.optionFilterApplied && shareItem?.subTitle?.toLowerCase().indexOf('equity') > -1)
      },
      async loadSummaryViewData () {
        try {
          this.loading = true
          this.date = this.date ? this.date : moment().format('YYYY-MM-DD')
          this.includeDraft = this.includeDraft ? this.includeDraft : false
          const resp = await captableService.getCaptableSummaryData(this.captableId, this.date, this.includeDraft)
          this.captableData = resp.data
          this.equitySummaryData = this.captableData.equity_summary
          await this.createAdminSummaryData()
          await this.getShareTermDetails()
          this.updateFinancingRoudView(this.captableFilters.include_financingRounds)
        } catch (err) {
          console.error('ERROR::', err) // TODO: remove once error message issue resolved (TPI-15655)
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
        this.loading = false
      },

      async getShareTermDetailsFromAPI () {
        try {
          const resp = await captableService.getShareTermList(this.captableId)
          this.shareTermList = resp.data.share_terms
        } catch (err) {
          this.loading = false
        }
      },

      async getWarrantTerms () {
        try {
          const resp = await captableService.getWarrantTermList(this.captableId)
          this.warrantTermList = resp.data.warrant_terms
        } catch (err) {
          this.loading = false
        }
      },

      async getConvertibleTerms () {
        try {
          const resp = await captableService.getConvertibleTermList(this.captableId)
          this.convertibleTermList = resp.data.convertible_terms
        } catch (err) {
          this.loading = false
        }
      },

      createAdminSummaryData () {
        let commonShareFinancing = []
        let preferredShareFinancing = []
        if (this.equitySummaryData) {
          const updatedFinancingRounds = this.mergeOthersData(this.equitySummaryData.others.share_terms, this.equitySummaryData.financing_rounds)
          let shareTermsList = updatedFinancingRounds.financingRounds.filter((item) => {
            return item.share_terms.length > 0
          })
          const otherShareTermRound = { share_terms: updatedFinancingRounds.others }
          shareTermsList = [...shareTermsList, otherShareTermRound]
          commonShareFinancing = this.getRoundListByTermType(shareTermsList, 'share_terms', this.CONSTS.SHARE_TERM_TYPES.COMMON)
          preferredShareFinancing = this.getRoundListByTermType(shareTermsList, 'share_terms', this.CONSTS.SHARE_TERM_TYPES.PREFERRED)
          const commonWarrantRounds = this.getWarrantTermsAsPerShareType(this.CONSTS.SHARE_TERM_TYPES.COMMON)
          const preferredWarrantRounds = this.getWarrantTermsAsPerShareType(this.CONSTS.SHARE_TERM_TYPES.PREFERRED)
          if (commonWarrantRounds && commonWarrantRounds.length > 0) {
            commonShareFinancing = this.mergeShareAndWarrantsByType(commonShareFinancing, commonWarrantRounds)
          }
          if (preferredWarrantRounds && preferredWarrantRounds.length > 0) {
            preferredShareFinancing = this.mergeShareAndWarrantsByType(preferredShareFinancing, preferredWarrantRounds)
          }
        }
        if (this.isHolder) {
          commonShareFinancing = this.getUpdatedHoldersData(commonShareFinancing)
          preferredShareFinancing = this.getUpdatedHoldersData(preferredShareFinancing)
        }
        commonShareFinancing.forEach((item) => {
          item.share_terms = this.getSortedListByBoardDate(item.share_terms)
          item.warrant_terms = this.getSortedListByBoardDate(item.warrant_terms, 'warrant')
        })
        preferredShareFinancing.forEach((item) => {
          item.share_terms = this.getSortedListByBoardDate(item.share_terms)
          item.warrant_terms = this.getSortedListByBoardDate(item.warrant_terms, 'warrant')
        })
        this.filteredSummaryData = [
          {
            title: 'Common Shares',
            shareList: commonShareFinancing,
            expanded: [],
          }, {
            title: 'Preferred Shares',
            shareList: preferredShareFinancing,
            expanded: [],
          },
        ]
        let i = 0
        this.filteredSummaryData.forEach(element => {
          element.shareList.forEach(item => {
            item.index = i++
            const shareTermNumOutstanding = parseFloat(this.getCalculatedSum(item.share_terms, 'number_outstanding'))
            const shareTermRatioOutstanding = (this.getCalculatedSum(item.share_terms, 'ratio_outstanding') * 100).toFixed(2)
            const shareTermNumFullyDiluted = parseFloat(this.getCalculatedSum(item.share_terms, 'number_fully_diluted'))
            const shareTermRatioFullyDiluted = this.getCalculatedSum(item.share_terms, 'ratio_fully_diluted')
            const warrantTermNumFullyDiluted = parseFloat(this.getCalculatedSum(item.warrant_terms, 'number_fully_diluted'))
            const warrantTermRatioFullyDiluted = this.getCalculatedSum(item.warrant_terms, 'ratio_fully_diluted')
            item.total = {
              number_outstanding: this.$number(shareTermNumOutstanding),
              ratio_outstanding: shareTermRatioOutstanding,
              number_fully_diluted: this.$number(shareTermNumFullyDiluted + warrantTermNumFullyDiluted),
              ratio_fully_diluted: ((shareTermRatioFullyDiluted + warrantTermRatioFullyDiluted) * 100).toFixed(2),
            }
          })
        })
      },

      mergeShareAndWarrantsByType (list1, list2) {
        const removeList = []
        for (const round1 of list1) {
          for (const round2 of list2) {
            if (round1.id === round2.id) {
              round1.warrant_terms = round2.warrant_terms
              removeList.push(round2.id)
              break
            }
          }
        }
        list2 = list2.filter(item => !removeList.includes(item.id))
        if (list2 && list2.length > 0) {
          list1 = [...new Set(list1.concat(list2))]
        }
        return list1
      },

      getSortedListByBoardDate (list, type) {
        list.forEach((term) => {
          if (term) {
            let linkedTermDetails = term
            if (type === 'warrant') {
              linkedTermDetails = this.warrantTermList.find(w => w.id === term.warrant_terms_id)
            }
            if (type === 'convertible') {
              linkedTermDetails = this.convertibleTermList.find(w => w.id === term.id)
            }
            const detailedTermData = this.shareTermList.find(s => linkedTermDetails.share_terms_id === s.id)
            term.board_approved_on = detailedTermData.board_approved_on
          }
        })
        list.sort((a, b) => {
          return new Date(a.board_approved_on) - new Date(b.board_approved_on)
        })
        list = this.getSortedListByHolderName(list)
        return list
      },

      getSortedListByHolderName (list) {
        list.forEach((term) => {
          if (term.shares && term.shares.length > 0) {
            term.shares = this.getSortedHolderList(term.shares)
          }
          if (term.convertibles && term.convertibles.length > 0) {
            term.convertibles = this.getSortedHolderList(term.convertibles)
          }
          if (term.warrants && term.warrants.length > 0) {
            term.warrants = this.getSortedHolderList(term.warrants)
          }
          if (term.options && term.options.length > 0) {
            term.options = this.getSortedHolderList(term.options)
          }
        })
        return list
      },

      getSortedHolderList (list) {
        list.sort((a, b) => {
          const aName = a.holder.name.toLowerCase()
          const bName = b.holder.name.toLowerCase()
          if (aName < bName) { return -1 }
          if (aName > bName) { return 1 }
          return 0
        })
        return list
      },

      getWarrantTermsAsPerShareType (shareType) {
        let commonWarrantList = []
        let preferredWarrantList = []

        const updatedFinancingRounds = this.mergeOthersData(this.equitySummaryData.others.warrant_terms, this.equitySummaryData.financing_rounds)
        let warrantsFinancing = updatedFinancingRounds.financingRounds.filter((item) => {
          return item.warrant_terms.length > 0
        })
        const otherWarranrTermRound = { warrant_terms: updatedFinancingRounds.others }
        warrantsFinancing = [...warrantsFinancing, otherWarranrTermRound]
        warrantsFinancing.forEach(element => {
          element.share_terms = []
          element.warrant_terms.forEach((warrantTerm) => {
            const linkedWarrantTermDetails = this.warrantTermList.find(w => w.id === warrantTerm.warrant_terms_id)
            const linkedShareTermDetails = this.shareTermList.find(s => linkedWarrantTermDetails.share_terms_id === s.id)
            warrantTerm.share_terms_type = linkedShareTermDetails?.type || ''
            warrantTerm.board_approved_on = linkedShareTermDetails?.board_approved_on || ''
          })
        })
        commonWarrantList = this.getRoundListByTermType(warrantsFinancing, 'warrant_terms', this.CONSTS.SHARE_TERM_TYPES.COMMON)
        preferredWarrantList = this.getRoundListByTermType(warrantsFinancing, 'warrant_terms', this.CONSTS.SHARE_TERM_TYPES.PREFERRED)
        return shareType === this.CONSTS.SHARE_TERM_TYPES.COMMON ? commonWarrantList : preferredWarrantList
      },

      getRoundListByTermType (roundList, termKey, shareTermType) {
        let commonSecurities = []
        let preferredSecurities = []
        const commonShareFinancing = []
        const preferredShareFinancing = []
        roundList.forEach((item) => {
          commonSecurities = item[termKey].filter(term => term.share_terms_type === this.CONSTS.SHARE_TERM_TYPES.COMMON)
          preferredSecurities = item[termKey].filter(term => term.share_terms_type === this.CONSTS.SHARE_TERM_TYPES.PREFERRED)
          if (commonSecurities.length > 0) {
            const commonSecurityItem = JSON.parse(JSON.stringify(item))
            commonSecurityItem.share_terms = termKey === 'share_terms' ? commonSecurities : []
            commonSecurityItem.warrant_terms = termKey === 'warrant_terms' ? commonSecurities : []
            commonShareFinancing.push(commonSecurityItem)
          }
          if (preferredSecurities.length > 0) {
            const preferredSecurityItem = JSON.parse(JSON.stringify(item))
            preferredSecurityItem.share_terms = termKey === 'share_terms' ? preferredSecurities : []
            preferredSecurityItem.warrant_terms = termKey === 'warrant_terms' ? preferredSecurities : []
            preferredShareFinancing.push(preferredSecurityItem)
          }
        })
        return shareTermType === this.CONSTS.SHARE_TERM_TYPES.COMMON ? commonShareFinancing : preferredShareFinancing
      },

      getFilteredHolderData (data, termKey, securityKey, holderId) {
        const updatedTerms = []
        data[termKey].forEach((term) => {
          const filteredShare = term[securityKey].filter(share => share.holder.id === holderId)
          if (filteredShare && filteredShare.length > 0) {
            term[securityKey] = filteredShare
            updatedTerms.push(term)
          }
        })
        return updatedTerms
      },

      getUpdatedHoldersData (captableData) {
        const holderId = this.$store.getters['auth/user']?.id
        const updatedData = []
        captableData.forEach((data) => {
          if (data.share_terms && data.share_terms.length > 0) {
            const updatedShareTerms = this.getFilteredHolderData(data, 'share_terms', 'shares', holderId)
            if (updatedShareTerms && updatedShareTerms.length > 0) {
              data.share_terms = updatedShareTerms
              updatedData.push(data)
            }
          }
          if (data.warrant_terms && data.warrant_terms.length > 0) {
            const updatedWarrantTerms = this.getFilteredHolderData(data, 'warrant_terms', 'warrants', holderId)
            if (updatedWarrantTerms && updatedWarrantTerms.length > 0) {
              data.warrant_terms = updatedWarrantTerms
              updatedData.push(data)
            }
            const i = updatedData.findIndex(d => d.id === data.id)
            if (i > -1 && updatedData[i]) {
              updatedData[i].warrant_terms = updatedWarrantTerms
            }
          }
          if (data.optionList && data.optionList.length > 0) {
            const updatedEIPs = this.getFilteredHolderData(data, 'optionList', 'options', holderId)
            if (updatedEIPs && updatedEIPs.length > 0) {
              data.optionList = updatedEIPs
              updatedData.push(data)
            }
            const i = updatedData.findIndex(d => d.id === data.id)
            if (i > -1 && updatedData[i]) {
              updatedData[i].optionList = updatedEIPs
            }
          }
        })
        return updatedData
      },

      getUpdatedOtherSecuritiesForHolder () {
        const holderId = this.$store.getters['auth/user']?.id
        const orgData = this.captableData && this.captableData.convertible_summary && this.captableData.convertible_summary.financing_rounds
        const updatedData = []
        if (orgData && orgData.length > 0) {
          orgData.forEach((data) => {
            if (data.convertible_terms && data.convertible_terms.length > 0) {
              const updatedTerms = this.getFilteredHolderData(data, 'convertible_terms', 'convertibles', holderId)
              if (updatedTerms && updatedTerms.length > 0) {
                data.convertible_terms = updatedTerms
                updatedData.push(data)
              }
            }
          })
        }
        return updatedData
      },

      mergeOthersData (others, financingRounds) {
        const othersIndx = []
        let idx = 0
        others.forEach((o) => {
          financingRounds.forEach((f) => {
            if (f.share_terms && f.share_terms.length > 0) {
              for (const i in f.share_terms) {
                if (o.share_terms_id === f.share_terms[i].share_terms_id) {
                  f = this.getMergedData(f, i, o, 'share_terms', 'shares')
                  othersIndx.push(idx)
                }
              }
            }
            if (f.warrant_terms && f.warrant_terms.length > 0) {
              for (const i in f.warrant_terms) {
                if (o.warrant_terms_id === f.warrant_terms[i].warrant_terms_id) {
                  f = this.getMergedData(f, i, o, 'warrant_terms', 'warrants')
                  othersIndx.push(idx)
                }
                f.warrant_terms[i].warrants = this.removeNoValueHolders(f.warrant_terms[i].warrants, 'number_fully_diluted')
              }
            }
          })
          idx++
        })
        othersIndx.forEach((index) => {
          others.splice(index, 1)
        })
        return { others: others, financingRounds: financingRounds }
      },

      removeNoValueHolders (list, key) {
        const noWarrantIdx = []
        for (const j in list) {
          const currentWarrant = list[j]
          if (parseInt(currentWarrant[key]) > 0) {
            noWarrantIdx.push(currentWarrant)
          }
        }
        return noWarrantIdx
      },

      getMergedData (round, idx, other, termKey, shareKey) {
        round[termKey][idx].number_fully_diluted = parseFloat(round[termKey][idx].number_fully_diluted) + parseFloat(other.number_fully_diluted)
        round[termKey][idx].number_outstanding = parseFloat(round[termKey][idx].number_outstanding) + parseFloat(other.number_outstanding)
        round[termKey][idx].ratio_fully_diluted = parseFloat(round[termKey][idx].ratio_fully_diluted) + parseFloat(other.ratio_fully_diluted)
        round[termKey][idx].ratio_outstanding = parseFloat(round[termKey][idx].ratio_outstanding) + parseFloat(other.ratio_outstanding)
        if (round[termKey][idx][shareKey] && round[termKey][idx][shareKey].length > 0) {
          round[termKey][idx][shareKey] = this.getMergedHolders(round[termKey][idx][shareKey], other[shareKey])
        }
        return round
      },

      getMergedHolders (orgHolderList, extraHolderList) {
        const existingHolderList = []
        const newHolderList = []
        const allData = []
        orgHolderList.forEach((o) => {
          for (const e of extraHolderList) {
            if (o.holder.id === e.holder.id) {
              o.number_fully_diluted = parseFloat(o.number_fully_diluted) + parseFloat(e.number_fully_diluted)
              o.number_outstanding = parseFloat(o.number_outstanding) + parseFloat(e.number_outstanding)
              o.ratio_fully_diluted = parseFloat(o.ratio_fully_diluted) + parseFloat(e.ratio_fully_diluted)
              o.ratio_outstanding = parseFloat(o.ratio_outstanding) + parseFloat(e.ratio_outstanding)
              existingHolderList.push(e)
            } else {
              const existingHolder = existingHolderList?.find((holder) => holder?.holder?.id === e?.holder?.id)
              const newHolderIndex = newHolderList?.findIndex((holder) => holder?.holder.id === e?.holder?.id)
              if ((!existingHolder || Object.keys(existingHolder)?.length === 0) && (newHolderList?.length === 0 || newHolderIndex === -1)) {
                newHolderList.push(e)
              }
            }
          }
        })
        orgHolderList = [...orgHolderList, ...newHolderList]
        orgHolderList.forEach((data) => {
          if (parseInt(data.number_fully_diluted) > 0 || parseInt(data.number_outstanding) > 0) {
            allData.push(data)
          }
        })
        return allData
      },

      getShareTermDetails (item) {
        if (this.shareTermList && item && item.share_terms) {
          item.securities = []
          item.share_terms.forEach((term) => {
            this.shareTermList.forEach((data) => {
              if (data.id === term.share_terms_id) {
                item.securities.push(data)
              }
            })
          })
        }
      },

      updateAsofDate (value) {
        this.captableFilters = Object.assign({}, value) || ''
      },

      customFilter (value, search, items) {
        const wordArray = search
          .toString()
          .toLowerCase()
          .split(' ')
          .filter(x => x)
        return wordArray.every(word =>
          JSON.stringify(Object.values(items))
            .toString()
            .toLowerCase()
            .includes(word),
        )
      },

      getOtherShareTerm (otherItem, type) {
        const other = []
        otherItem.find((item) => {
          if (item.share_terms_type === type) {
            other.push(item)
          }
        })
        return other
      },

      getWarrantData (type) {
        this.filteredSummaryData.forEach((item) => {
          if (item && item.warrant_terms && item.warrant_terms.length > 0) {
            item.warrant_terms = item.warrant_terms.filter((term) => {
              term = { ...term, warrant_terms_type: this.CONSTS.SHARE_TERM_TYPES.WARRANT }
              if (term.warrant_terms_type === type) {
                return term
              }
            })
          }
        })
        return this.filteredSummaryData.filter((item) => {
          if (item && item.warrant_terms && item.warrant_terms.length > 0) {
            return item
          }
        })
      },
      getPreferredData (type) {
        this.filteredSummaryData.forEach((item) => {
          if (item && item.share_terms && item.share_terms.length > 0) {
            item.share_terms = item.share_terms.filter((term) => {
              if (!type || (type && term.share_terms_type === type)) {
                return term
              }
            })
          }
        })
        return this.filteredSummaryData.filter((item) => {
          if (item && item.share_terms && item.share_terms.length > 0) {
            return item
          }
        })
      },
      toggleExpanded (e) {
        this.filterExpanded = !this.filterExpanded
      },
      navigateToDetailsPage (id, type) {
        if (this.isHolder) {
          this.$router.push({ name: 'Holder.Holdings', params: { id: id, type: type } })
        }
      },
      calculateSum (list, key) {
        let total = 0
        list.forEach(item => {
          if (item && item.share_terms && item.share_terms.length > 0) {
            total += this.getCalculatedSum(item.share_terms, key)
          }
          if (key !== 'number_outstanding' && key !== 'ratio_outstanding' && item && item.warrant_terms && item.warrant_terms.length > 0) {
            total += this.getCalculatedSum(item.warrant_terms, key)
          }
        })
        return total
      },
      calculateEipSum (list, key) {
        let total = 0
        list.forEach(item => {
          if (item && item.share_terms && item.share_terms.length > 0) {
            total += this.getCalculatedSum(item.share_terms, key)
          }
          if (key !== 'number_outstanding' && key !== 'ratio_outstanding' && item && item.optionList && item.optionList.length > 0) {
            item.optionList.forEach(data => {
              total += this.getCalculatedSum(data.options, key)
            })
          }
        })
        return total
      },
      getCalculatedSum (list, key) {
        let total = 0
        if (list?.length > 0) {
          total = list.reduce((accumulator, currentValue) => {
            (total += +currentValue[key])
            return total
          }, 0)
        }
        return total
      },
      updateFinancingRoudView (showFinancingRound) {
        if (!showFinancingRound) {
          const sharesData = JSON.parse(JSON.stringify(this.filteredSummaryData))
          const othersData = JSON.parse(JSON.stringify(this.otherSecurities))
          this.summaryData = {
            sharesData: sharesData,
            othersData: othersData,
          }
        }
      },
      getAmountInThousands (amount) {
        if (amount > 0) {
          amount = amount / 1000
        }
        return amount
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    -webkit-box-shadow:none !important;
    box-shadow: none !important;
  }
  .footer-bg {
    background: var(--v-accent-base);
    overflow: hidden;
  }
  .headerStyle {
    justify-content: space-between;
  }
  .filter-icon {
    top: 20px;
  }
  .inner-table {
    padding: 0 !important;
    overflow-x: hidden;
  }
  .mr-m2 {
    margin-right: -2px;
  }
</style>
